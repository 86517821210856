import styled, { css } from "styled-components";

import { Theme } from "@anwb/poncho/design-tokens/theme";

type DefaultThemeProps = {
  theme: Theme;
};

const progressBarWrapperStyling = () => css`
  display: flex;
  align-items: baseline;
  width: 20rem;
`;

export const ProgressBarWrapper = styled.div`
  ${progressBarWrapperStyling}
`;

const countdownValueStyling = ({ theme }: DefaultThemeProps) => css`
  color: ${theme.colors.highlight.fillProgressBar};
  padding-right: 1rem;
`;

export const CountdownValue = styled.div`
  ${countdownValueStyling};
`;
