import { useDraggable } from "@dnd-kit/core";

import { DraggableButton } from "./styles/draggable.styled";
import { StyledIcon } from "./styles/styledIcon.styled";

type DraggableProps = {
  isHidden: boolean;
};

export const DraggableSingle = ({ isHidden }: DraggableProps) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: "draggable",
  });

  return (
    <DraggableButton
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      id={"draggable"}
      transform={transform}
      isDragging={isDragging}
      isHidden={isHidden}
    >
      <StyledIcon />
    </DraggableButton>
  );
};
