import styled from "styled-components";

import { PageTitle } from "@anwb/poncho/components/typography";

export const TitleMedium = styled(PageTitle)`
  && {
    font-size: 2rem;
    line-height: 1.2;
  }
`;

export default TitleMedium;
