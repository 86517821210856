import { css } from "styled-components";

export const defaultFontStyle = () => css`
  font-family: "Bernina Sans Narrow H5P CT", "Bernina Sans Narrow", "Bernina Sans", Arial,
    sans-serif;
  font-weight: normal;
  line-height: 1.6;
  font-size: 15px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
