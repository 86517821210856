import ReactPlayer from "react-player";

import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

const reactPlayerWrapperStyling = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 14rem);
  margin: auto 0;

  @media screen and (orientation: landscape) and (max-width: ${pxToRem(
      theme.viewportBreakpoint.md,
    )}) {
    max-height: calc(-9rem + 100vh);
  }
`;

export const ReactPlayerWrapper = styled.div`
  ${reactPlayerWrapperStyling};
`;

const reactPlayerElementStyling = () => css`
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 1.5rem;
  }
`;

export const ReactPlayerElement = styled(ReactPlayer)`
  ${reactPlayerElementStyling};
`;
