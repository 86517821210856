import { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";

import { MarkdownSanitized } from "@components/MarkdownSanitized/MarkdownSanititzed";
import { ScaledImage } from "content-types-shared/src/components/Image/ScaledImage";
import { Navigation, Scrollbar } from "swiper/modules";

import { ImageContainer } from "@anwb/poncho/components/card/BrandRefreshCard/components/Image/styles/image.styled";

import { ContentBlock } from "../../types/contentblock";
import { ContentHeader } from "../ContentHeader/ContentHeader";
import HeaderText from "../HeaderText/HeaderText";
import SubHeaderText from "../SubHeaderText/SubHeaderText";

import {
  CustomSwiper,
  FirstSlideArrow,
  FirstSlideContainer,
  FirstSlideSlideAnimation,
  FirstSlideText,
  LastSlide,
  ScrollableText,
  StyledContentBlockText,
  StyledSwiperButton,
  StyledSwiperButtonContainer,
  StyledSwiperSlide,
  StyledTitleSwiperSlide,
  SwiperScrollbar,
} from "./styles/content.styled";

export enum SwiperTypeVariant {
  BUTTONS,
  SCROLLBAR,
}

const ContentBlocksSwiper = ({
  title,
  variant,
  contentBlocks,
}: {
  title: string;
  variant: SwiperTypeVariant;
  contentBlocks: ContentBlock[];
}) => {
  const swiperConfig = {
    spaceBetween: 25,
    slidesPerView: 1,
    slidesPerGroup: 1,
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      820: {
        slidesPerView: 1.75,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      1400: {
        slidesPerView: 2.4,
        spaceBetween: 25,
      },
    },
    freeMode: false,
    resistanceRatio: 0,
    touchRatio: 1,
    ...(variant === SwiperTypeVariant.BUTTONS && {
      modules: [Navigation],
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
        disabledClass: "button-disabled",
      },
    }),
    ...(variant === SwiperTypeVariant.SCROLLBAR && {
      scrollbar: { el: ".swiperScrollbar", draggable: true, hide: false },
      modules: [Scrollbar],
      longSwipes: true,
      longSwipesRatio: 0.5,
    }),
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 599);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 599);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <CustomSwiper {...swiperConfig} $hasButtonsToNavigate={variant === SwiperTypeVariant.BUTTONS}>
        {isMobile && (
          <StyledTitleSwiperSlide>
            <HeaderText title={title} />
            <SubHeaderText text={"Bekijk de volgende slide voor meer informatie."} />
            {variant === SwiperTypeVariant.BUTTONS && <FirstSlideArrow />}
            {variant === SwiperTypeVariant.SCROLLBAR && (
              <FirstSlideContainer>
                <FirstSlideSlideAnimation />
                <FirstSlideText>Swipe</FirstSlideText>
              </FirstSlideContainer>
            )}
          </StyledTitleSwiperSlide>
        )}

        {contentBlocks.map((contentBlock, index) => (
          <StyledSwiperSlide key={index}>
            {contentBlock.imageUrl && (
              <Zoom>
                <ImageContainer>
                  <ScaledImage imageUrl={contentBlock.imageUrl} />
                </ImageContainer>
              </Zoom>
            )}
            <ScrollableText>
              {contentBlock.title && <ContentHeader text={contentBlock.title} />}
              <MarkdownSanitized markdown={contentBlock.text} container={StyledContentBlockText} />
            </ScrollableText>
          </StyledSwiperSlide>
        ))}
        <StyledSwiperSlide $isLastSlide={true}>
          <LastSlide />
        </StyledSwiperSlide>
        {variant === SwiperTypeVariant.SCROLLBAR && (
          <SwiperScrollbar className="swiperScrollbar"></SwiperScrollbar>
        )}
        {variant === SwiperTypeVariant.BUTTONS && (
          <StyledSwiperButtonContainer>
            <StyledSwiperButton icon="arrow-left" iconPosition="before" className="button-prev">
              Vorige
            </StyledSwiperButton>
            <StyledSwiperButton icon="arrow-right" className="button-next">
              Volgende
            </StyledSwiperButton>
          </StyledSwiperButtonContainer>
        )}
      </CustomSwiper>
    </div>
  );
};

export default ContentBlocksSwiper;
