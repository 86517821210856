import { decodeHtmlEntities } from "content-types-shared/src/utils/decodeHtmlEntities";

import { TitleText } from "./styles/headerText.styled";

type QuestionTitleProps = {
  title: string;
  hideOnMobile?: boolean;
};

const HeaderText = ({ title, hideOnMobile }: QuestionTitleProps) => (
  <TitleText hideOnMobile={hideOnMobile}>{decodeHtmlEntities(title)}</TitleText>
);

export default HeaderText;
