import ReactPlayer from "react-player";

import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Notification from "content-types-shared/src/components/Notification/Notification";
import useResponsiveBynderVideo from "content-types-shared/src/hooks/useResponsiveBynderVideo";

import { VideoProperties } from "../types/types";

import { ReactPlayerElement, ReactPlayerWrapper } from "./styles";

type ContentProps = {
  video: VideoProperties;
};

enum Language {
  NL = "nl",
  EN = "en",
}

function getTracks(video: VideoProperties) {
  const tracks = [
    {
      kind: "subtitles",
      src: video.subtitleURL,
      srcLang: Language.NL,
      default: isLanguageDefault(Language.NL),
    },
  ];

  if (video.subtitleEnglishURL !== undefined) {
    tracks.push({
      kind: "subtitles",
      src: video.subtitleEnglishURL,
      srcLang: Language.EN,
      default: isLanguageDefault(Language.EN),
    });
  }
  return tracks;
}

function isLanguageDefault(language: Language): boolean {
  const isSubtitleShowing = localStorage.getItem("content-type-video-subtitle") ?? "showing";
  const preferredLanguage =
    localStorage.getItem("content-type-video-subtitle-preferred-language") ?? Language.NL;
  return isSubtitleShowing === "showing" && language === preferredLanguage;
}

function onChangeSelectedSubtitle(event: Event) {
  const trackList = event.currentTarget as TextTrackList;
  let preferredLanguage: string =
    localStorage.getItem("content-type-video-subtitle-preferred-language") ?? Language.NL;
  let subtitleSetting = "disabled";
  for (let i = 0; i < trackList.length; i++) {
    if (trackList[i].mode === "showing") {
      subtitleSetting = "showing";
      preferredLanguage = trackList[i].language;
    }
  }

  localStorage.setItem("content-type-video-subtitle", subtitleSetting);
  localStorage.setItem("content-type-video-subtitle-preferred-language", preferredLanguage);
}

const MAX_SCORE = 1;

const Content = ({ video }: ContentProps) => {
  const dispatcher = useServerSideEventDispatcher();
  const videoUrl = useResponsiveBynderVideo(video.videoURL);
  return (
    <ReactPlayerWrapper>
      {videoUrl === "" ? (
        <Notification
          variant="warning"
          title="Deze video kan niet getoond worden vanwege een onvolledige configuratie"
        >
          Tip voor auteur: de URL voor deze video ontbreekt.
        </Notification>
      ) : (
        <ReactPlayerElement
          url={videoUrl}
          controls
          pip={false}
          width="auto"
          height="auto"
          onError={(error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) =>
            console.error(error, data, hlsInstance, hlsGlobal)
          }
          config={{
            file: {
              attributes: {
                crossOrigin: "anonymous",
                controlsList: "nodownload",
                disablePictureInPicture: true,
              },
              tracks: getTracks(video),
            },
          }}
          onPlay={() => {
            dispatcher.dispatch({ score: MAX_SCORE, maxScore: MAX_SCORE });
          }}
          onReady={(player: ReactPlayer) => {
            if (player.getInternalPlayer()?.textTracks) {
              (player.getInternalPlayer().textTracks as TextTrackList).addEventListener(
                "change",
                onChangeSelectedSubtitle,
              );
            }
          }}
        />
      )}
    </ReactPlayerWrapper>
  );
};

export default Content;
