import { useDraggable } from "@dnd-kit/core";

import { DraggableButton } from "./styles/draggable.styled";
import { StyledNumber } from "./styles/styledNumber.styled";

type DraggableProps = {
  option: number;
  isHidden: boolean;
};

export const DraggableMultiple = ({ option, isHidden }: DraggableProps) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: "draggable" + option,
  });

  return (
    <DraggableButton
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      id={"draggable" + option}
      transform={transform}
      isDragging={isDragging}
      isHidden={isHidden}
    >
      <StyledNumber>{option + 1}</StyledNumber>
    </DraggableButton>
  );
};
