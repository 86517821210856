import { BodyLarge } from "content-types-shared/src/typography";
import styled, { css, keyframes } from "styled-components";
import { SwiperSlide } from "swiper/react";

import { StyledSwiper } from "@anwb/poncho/components/horizontal-scroll/styles/swiper.styles";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import FirstSlideArrowImage from "../../../images/first-slide-arrow.svg";
import FirstSlideSlideAnimationImage from "../../../images/first-slide-slide-animation.svg";
import LastSlideImage from "../../../images/last-slide.png";
import { scrollbarStyling } from "../../../styling/grid.styled";
import { getAbsoluteUrlToAsset } from "../../../styling/index.styled";
import { customVariables } from "../../../theme/custom-variables";
import ButtonDarkTertiary from "../../ButtonDarkTertiary";

const customSwiperStyles = ({ theme }: DefaultThemeProps) => css`
  position: relative;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      background: linear-gradient(270deg, ${customVariables.colors.bodyBackground}, transparent);
      z-index: 1;
    }
  }
`;
const swiperSlideHeightStyling = () => css`
  height: auto !important;
  min-height: 25rem;
`;
const customSwiperButtonStyles = ({ theme }: DefaultThemeProps) => css`
  .swiper-wrapper {
    max-height: calc(100dvh - 15rem);
  }
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    .swiper-wrapper {
      max-height: calc(100vh - 20rem);
    }
  }
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      background: linear-gradient(270deg, ${customVariables.colors.bodyBackground}, transparent);
      z-index: 1;
    }
  }
`;
const customSwiperNavigateStyles = ({ theme }: DefaultThemeProps) => css`
  .swiper-wrapper {
    max-height: calc(100dvh - 13rem);
  }
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    .swiper-wrapper {
      max-height: calc(100vh - 17rem);
    }
  }
`;

export const CustomSwiper = styled(StyledSwiper)<{ $hasButtonsToNavigate: boolean }>`
  ${customSwiperStyles}
  ${({ $hasButtonsToNavigate }) =>
    $hasButtonsToNavigate ? customSwiperButtonStyles : customSwiperNavigateStyles}
`;

export const StyledSwiperSlide = styled(SwiperSlide)<{ $isLastSlide?: boolean }>`
  position: relative;
  cursor: grab;
  transition: opacity 0.15s ease-in-out;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;

  ${swiperSlideHeightStyling}
  ${scrollbarStyling}

  &.swiper-slide-active {
    opacity: 1 !important;
  }

  ${(props) =>
    props.$isLastSlide
      ? css`
          display: flex;
          align-content: center;
          justify-content: center;
          opacity: 1 !important;

          img {
            height: 100%;
          }
        `
      : css`
          border-radius: 0.5rem;
          background-color: ${customVariables.colors.backgroundBlue};
          border: 1px solid ${customVariables.colors.swiperSlideBorder};

          &:after {
            content: "";
            position: sticky;
            width: 100%;
            height: 20px;
            bottom: 0;
            background: linear-gradient(
              ${customVariables.colors.backgroundBlue}00,
              ${customVariables.colors.backgroundBlue}
            );
            display: block;
          }
        `};

  &:active {
    cursor: grabbing;
  }

  img {
    border-radius: 0;
  }
`;

export const ScrollableText = styled.div`
  padding: 1.6rem 1.6rem 0;
`;

export const SwiperScrollbar = styled.div`
  position: relative;
  height: 5px;
  margin-top: 1rem;
  border-radius: 5px;
  background-color: ${customVariables.colors.swiperScrollbar};

  > div {
    background-color: #fff;
    cursor: grab;

    &:active {
      height: 5px !important;
      top: 0 !important;
      margin-top: 0 !important;
      cursor: grabbing;
    }
  }
`;

export const LastSlide = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("${getAbsoluteUrlToAsset(LastSlideImage)}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
export const StyledSwiperButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;
export const StyledSwiperButton = styled(ButtonDarkTertiary)`
  height: 2.5rem;
  font-size: 1rem;
  transition: 0.15s;
  &.button-disabled {
    opacity: 0.25;
    background-color: transparent;
  }
`;

export const StyledTitleSwiperSlide = styled(SwiperSlide)`
  display: flex !important;
  flex-direction: column;
  gap: 1.6rem;

  ${swiperSlideHeightStyling}
`;

export const FirstSlideArrow = styled.div`
  width: 200px;
  height: 200px;
  margin: auto 5rem 0 auto;
  background-image: url("${getAbsoluteUrlToAsset(FirstSlideArrowImage)}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const FirstSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FirstSlideTextStyling = ({ theme }: DefaultThemeProps) => css`
  color: ${theme.colors.base.textBody};
  margin: 0;
  font-size: 1.8rem;
  line-height: 1;
  font-family: "ClanPro Compressed Bold", Arial, sans-serif;
`;
export const FirstSlideText = styled.p`
  ${FirstSlideTextStyling}
`;

export const animateImage = () => keyframes`
  0% {
    opacity: 0;
    right: -50px;
  }
  10% {
    opacity: 1
  }
  80%{
    opacity: 1;
  }
  90%{
    opacity: 0;
    right: 50px;
  }
  100%{
    opacity: 0;
  }
`;
export const FirstSlideSlideAnimation = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 2rem 0 1rem;
  background-image: url("${getAbsoluteUrlToAsset(FirstSlideSlideAnimationImage)}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: ${animateImage} 2s infinite ease-in-out;
`;

const contentTextStyled = ({ theme }: DefaultThemeProps) => css`
  margin-bottom: 0.8rem;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    margin-bottom: 1.6rem;
  }
`;

export const StyledContentBlockText = styled(BodyLarge).attrs({ tagName: "div" })`
  ${contentTextStyled}
`;
