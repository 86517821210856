import { PotentialAnswer } from "../../types/types";

import { AnswerButtonStatus } from "./styles/answerButton.styled";

export const getButtonStatus = (
  buttonAnswer: PotentialAnswer,
  selectedAnswer?: PotentialAnswer,
) => {
  if (selectedAnswer?.id === buttonAnswer.id) {
    if (selectedAnswer?.correct) {
      return AnswerButtonStatus.ANSWER_IS_CORRECT;
    }
    return AnswerButtonStatus.ANSWER_IS_WRONG;
  }
  return AnswerButtonStatus.ANSWER_DEFAULT;
};

export const getButtonStatusMultipleSelect = (
  buttonId: string,
  selectedAnswers: PotentialAnswer[],
  correctAnswers: PotentialAnswer[],
  showFeedback: boolean,
) => {
  if (selectedAnswers.some((selectedAnswer) => selectedAnswer.id === buttonId)) {
    if (showFeedback) {
      if (correctAnswers.some((answer) => answer.id === buttonId)) {
        return AnswerButtonStatus.ANSWER_IS_CORRECT;
      }
      return AnswerButtonStatus.ANSWER_IS_WRONG;
    }
    return AnswerButtonStatus.ANSWER_IS_SELECTED;
  }

  if (showFeedback && correctAnswers.some((answer) => answer.id === buttonId)) {
    return AnswerButtonStatus.ANSWER_IS_MISSING;
  }
  return AnswerButtonStatus.ANSWER_DEFAULT;
};
