import { useEffect, useRef, useState } from "react";

import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { Coordinates } from "@dnd-kit/utilities";
import CheckOutAnswerAndRetryButton from "content-types-shared/src/components/CheckOutAnswerAndRefreshButton/CheckOutAnswerAndRetryButton";
import { DraggableSingle } from "content-types-shared/src/components/Draggable/DraggableSingle";
import { DroppableSingle } from "content-types-shared/src/components/Droppable/DroppableSingle";
import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Feedback from "content-types-shared/src/components/Feedback/Feedback";
import {
  ImageStyled,
  InteractiveWrapper,
} from "content-types-shared/src/components/styles/imageContainer.styled";
import useResizeObserver from "content-types-shared/src/hooks/useResizeObserver";
import { DraggableContainer } from "content-types-shared/src/styling/draggable.styled";
import {
  ImageGridItem,
  ScrollableGridItem,
  StyledGrid,
} from "content-types-shared/src/styling/grid.styled";
import { scaleBynderImage } from "content-types-shared/src/utils/image";

import { useContentTypeStore } from "../store/useContentTypeStore";
import { SingleSleepQuestion } from "../types/types";

type ImageProps = {
  question: SingleSleepQuestion;
};

const MIN_SCORE = 0;
const MAX_SCORE = 1;

export const ImageContainer = ({ question }: ImageProps) => {
  const dispatcher = useServerSideEventDispatcher();
  const [showFeedback, setShowFeedback] = useState(false);

  const imageRef = useRef(null);
  const { width } = useResizeObserver(imageRef);
  const resizeRatio =
    width !== undefined
      ? Math.round((width / question.resolution.width + Number.EPSILON) * 100) / 100
      : 1;

  const { userAnswer, setUserAnswer } = useContentTypeStore((state) => ({
    userAnswer: state.userAnswer,
    setUserAnswer: state.setUserAnswer,
  }));

  const showCheckAnswer = userAnswer !== undefined;
  const isCorrectAnswer = userAnswer !== undefined && question.potentialAnswers[userAnswer].correct;

  useEffect(() => {
    setUserAnswer(undefined);
    setShowFeedback(false);
  }, [question]);

  const endDrag = (onDragEndEvent: DragEndEvent) => {
    if (onDragEndEvent.over !== null) {
      const droppableId = (onDragEndEvent.over.id as string).replace("droppable", "");
      setUserAnswer(parseInt(droppableId));
    }
  };

  const clickDroppable = (event: any) => {
    const droppableId = event.currentTarget.id;
    const droppableNumber = droppableId.slice(-1);
    if (parseInt(droppableNumber) === userAnswer) {
      setUserAnswer(undefined);
    } else {
      setUserAnswer(parseInt(droppableNumber));
    }
  };

  const handleCheckAnswer = () => {
    setShowFeedback(true);

    const score = isCorrectAnswer ? MAX_SCORE : MIN_SCORE;
    dispatcher.dispatch({ score: score, maxScore: MAX_SCORE });
  };

  const resetAnswer = () => {
    setShowFeedback(false);
    setUserAnswer(undefined);
  };

  return (
    <>
      <DndContext onDragEnd={endDrag}>
        <StyledGrid columns={{ sm: 1, md: 1, lg: 2 }} justify="start">
          <ImageGridItem>
            <InteractiveWrapper data-id="interactive-wrapper">
              <ImageStyled
                ref={imageRef}
                src={scaleBynderImage(question.imageUrl)}
                width={question.resolution.width}
                height={question.resolution.height}
              />

              {question.potentialAnswers?.map((coordinates: Coordinates, index: number) => (
                <DroppableSingle
                  key={"droppable" + index}
                  coordinates={{
                    x: coordinates.x * resizeRatio,
                    y: coordinates.y * resizeRatio,
                  }}
                  option={index}
                  userAnswer={userAnswer}
                  onClick={clickDroppable}
                />
              ))}
            </InteractiveWrapper>
          </ImageGridItem>
          <ScrollableGridItem feedbackIsShown={showFeedback}>
            {!showCheckAnswer && (
              <DraggableContainer>
                <DraggableSingle key={"draggable"} isHidden={userAnswer !== undefined} />
              </DraggableContainer>
            )}

            {showCheckAnswer && !showFeedback && (
              <CheckOutAnswerAndRetryButton
                onClickRetryButton={resetAnswer}
                onClickShowAnswerButton={handleCheckAnswer}
              />
            )}
            {showFeedback && (
              <Feedback feedback={question.feedback} isCorrectAnswer={isCorrectAnswer} />
            )}
          </ScrollableGridItem>
        </StyledGrid>
      </DndContext>
    </>
  );
};
