import { createGlobalStyle } from "styled-components";

import { getAbsoluteUrlToAsset } from "../styling/index.styled";

import BerninaSansCompressed from "./fonts/BerninaSans-CompressedBold.otf";
import BerninaSansNarrow from "./fonts/BerninaSans-NarrowRegular.otf";
import BerninaSansNarrowSemibold from "./fonts/BerninaSans-NarrowSemibold.otf";
import ClanProCompBold from "./fonts/ClanPro-CompBold.otf";
import ClanProCompMedium from "./fonts/ClanPro-CompMedium.otf";
import ClanProMedium from "./fonts/ClanPro-Medium.otf";

export const CustomFonts = createGlobalStyle`
  @font-face {
    font-family: 'Bernina Sans Compressed H5P CT';
    src: url('${getAbsoluteUrlToAsset(BerninaSansCompressed)}') format('opentype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Bernina Sans Narrow H5P CT';
    src: url('${getAbsoluteUrlToAsset(BerninaSansNarrow)}') format('opentype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Bernina Sans Narrow H5P CT';
    src: url('${getAbsoluteUrlToAsset(BerninaSansNarrowSemibold)}') format('opentype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'ClanPro Compressed Bold';
    src: url('${getAbsoluteUrlToAsset(ClanProCompBold)}') format('opentype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'ClanPro Compressed Medium';
    src: url('${getAbsoluteUrlToAsset(ClanProCompMedium)}') format('opentype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'ClanPro Medium';
    src: url('${getAbsoluteUrlToAsset(ClanProMedium)}') format('opentype');
    font-weight: 600;
  }
`;
