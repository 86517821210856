import { RefObject, useEffect, useRef, useState } from "react";

export default <T extends HTMLElement>(ref: RefObject<T>) => {
  const [size, setSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  const observer = useRef<ResizeObserver>(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries.length === 0) {
        setSize({
          width: undefined,
          height: undefined,
        });
      } else {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
      }
    }),
  );

  useEffect(() => {
    const currentElement = ref.current;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [ref, observer]);

  return size;
};
