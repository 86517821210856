import Content from "./Content/Content";
import { IHotSpot } from "./types/types";

type AppProps = {
  hotspotQuestion: IHotSpot;
};

const Hotspot = ({ hotspotQuestion }: AppProps) =>
  hotspotQuestion && <Content hotspotQuestion={hotspotQuestion} />;

export default Hotspot;
