import { create } from "zustand";

import { UserAnswers } from "../types/types";

type ContentTypeStore = {
  showDropzones: boolean | undefined;
  userAnswers: UserAnswers;

  setShowDropzones: (showDropzones: boolean) => void;
  setUserAnswers: (userAnswers: UserAnswers) => void;
  reset: () => void;
};

const initialState = {
  showDropzones: false,
  userAnswers: {},
};

export const useContentTypeStore = create<ContentTypeStore>((set) => ({
  ...initialState,
  setShowDropzones: (showDropzones: boolean) => set({ showDropzones }),
  setUserAnswers: (userAnswers: any) => set({ userAnswers }),
  reset: () => set(initialState),
}));
