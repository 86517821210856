import styled from "styled-components";

import Typography from "@anwb/poncho/components/typography";

export const BodyLarge = styled(Typography).attrs(() => ({
  variant: "body-text",
}))`
  && {
    font-family: "Bernina Sans Narrow H5P CT", "Bernina Sans Narrow", "Bernina Sans", Arial,
      sans-serif;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.4;
  }
`;
export default BodyLarge;
