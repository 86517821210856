import styled, { css } from "styled-components";

import Grid from "@anwb/poncho/components/grid";
import GridItem from "@anwb/poncho/components/grid/GridItem";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "../theme/custom-variables";

interface StyledGridProps {
  overflowContent?: boolean;
}

interface ScrollableGridItemProps {
  feedbackIsShown: boolean;
}

export const scrollbarStyling = () => css`
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 14px;
    border: solid 3px transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border: solid 3px transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-width: 4px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #606060;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const scrollableGridItemStyling = ({ theme }: DefaultThemeProps) => css`
  position: relative;

  ${scrollbarStyling}

  &:after {
    content: "";
    position: sticky;
    width: 100%;
    height: 15px;
    bottom: -1px;
    display: block;
    background-image: linear-gradient(transparent, ${customVariables.colors.bodyBackground});
    pointer-events: none;
  }

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    overflow: unset;
  }
`;

export const ScrollableGridItem = styled(GridItem)<ScrollableGridItemProps>`
  ${({ feedbackIsShown }) =>
    feedbackIsShown &&
    css`
      overflow-y: auto;
    `}
  ${scrollableGridItemStyling};
`;
const styledGridStyling = ({ theme }: DefaultThemeProps) => css`
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    display: flex;
    overflow: hidden;
  }
`;

export const StyledGrid = styled(Grid)<StyledGridProps>`
  ${styledGridStyling};
  ${scrollbarStyling}

  ${({ overflowContent }) =>
    overflowContent &&
    css`
      overflow: visible;
    `}
`;
const imageGridItemStyling = () => css`
  display: flex;

  img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
`;

export const ImageGridItem = styled(GridItem)`
  ${imageGridItemStyling};
`;
