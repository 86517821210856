import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import ButtonDarkPrimary from "../ButtonDarkPrimary";
import ButtonDarkTertiary from "../ButtonDarkTertiary";

const buttonWrapperStyled = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  text-align: center;
  gap: ${pxToRem(theme.spacing["300"])};
  margin-top: 1rem;

  ${ButtonDarkTertiary}, ${ButtonDarkPrimary} {
    display: flex;
    flex-grow: 1;
  }
`;

export const ButtonWrapper = styled.div`
  ${buttonWrapperStyled}
`;
