import { useEffect, useMemo, useState } from "react";

import { getButtonStatusMultipleSelect } from "content-types-shared/src/components/AnswerButton/getButtonStatus";
import {
  Answer,
  AnswerButtonStatus,
  VerticalAnswerWrapper,
} from "content-types-shared/src/components/AnswerButton/styles/answerButton.styled";
import CheckOutAnswerAndRetryButton from "content-types-shared/src/components/CheckOutAnswerAndRefreshButton/CheckOutAnswerAndRetryButton";
import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Feedback, { ContentTypes } from "content-types-shared/src/components/Feedback/Feedback";
import HeaderText from "content-types-shared/src/components/HeaderText/HeaderText";
import { ScaledImage } from "content-types-shared/src/components/Image/ScaledImage";
import Notification from "content-types-shared/src/components/Notification/Notification";
import SubHeaderText from "content-types-shared/src/components/SubHeaderText/SubHeaderText";
import { ContentWrapper } from "content-types-shared/src/components/styles/contentWrapper.styled";
import {
  ImageGridItem,
  ScrollableGridItem,
  StyledGrid,
} from "content-types-shared/src/styling/grid.styled";
import { PotentialAnswer } from "content-types-shared/src/types/types";
import { decodeHtmlEntities } from "content-types-shared/src/utils/decodeHtmlEntities";

import { MultipleSelectQuestion } from "../types/types";

type ContentProps = {
  multipleSelect: MultipleSelectQuestion;
};

const MIN_SCORE = 0;
const MAX_SCORE = 1;

const Content = ({ multipleSelect }: ContentProps) => {
  const dispatcher = useServerSideEventDispatcher();
  const [selectedAnswers, setSelectedAnswers] = useState<PotentialAnswer[]>([]);
  const [showCheckAnswerAndResetButton, setShowCheckAnswerAndResetButton] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    setSelectedAnswers([]);
    setShowFeedback(false);
  });

  const resetAnswer = () => {
    setSelectedAnswers([]);
    setShowCheckAnswerAndResetButton(false);
    setShowFeedback(false);
  };

  const correctAnswers = useMemo(() => {
    return multipleSelect.potentialAnswers.filter((answer) => answer.correct);
  }, [multipleSelect.potentialAnswers]);

  const selectedCorrectAnswers = useMemo(() => {
    return selectedAnswers.filter((answer) => answer.correct).length;
  }, [selectedAnswers]);

  if (correctAnswers.length === 0) {
    return (
      <Notification
        variant="warning"
        title="Deze vraag kan niet getoond worden vanwege een onvolledige configuratie"
      >
        Tip voor auteur: geef aan welk antwoorden correct zijn.
      </Notification>
    );
  }
  const isAnswerSelected = (potentialAnswer: PotentialAnswer) =>
    selectedAnswers.some((selectedAnswer) => selectedAnswer.id === potentialAnswer.id);

  const isSelectedAnswerCorrect = () => {
    return multipleSelect.potentialAnswers.every((potentialAnswer) => {
      return (
        (potentialAnswer.correct &&
          selectedAnswers.some((selectedAnswer) => selectedAnswer.id === potentialAnswer.id)) ||
        (!potentialAnswer.correct &&
          !selectedAnswers.some((selectedAnswer) => selectedAnswer.id === potentialAnswer.id))
      );
    });
  };

  const toggleSelectedAnswer = (potentialAnswer: PotentialAnswer) => {
    if (isAnswerSelected(potentialAnswer)) {
      setSelectedAnswers(selectedAnswers.filter((answer) => answer.id !== potentialAnswer.id));
    } else {
      setSelectedAnswers([...selectedAnswers, potentialAnswer]);
    }

    const score = isSelectedAnswerCorrect() ? MAX_SCORE : MIN_SCORE;
    dispatcher.dispatch({ score: score, maxScore: MAX_SCORE });

    setShowCheckAnswerAndResetButton(true);
  };

  return (
    <>
      <ContentWrapper>
        <HeaderText title={multipleSelect.question} />
        <SubHeaderText text="Selecteer de juiste antwoorden" />
        <StyledGrid columns={{ sm: 1, md: 1, lg: 2 }} justify="start">
          <ImageGridItem>
            <ScaledImage imageUrl={multipleSelect.imageUrl} />
          </ImageGridItem>
          <ScrollableGridItem feedbackIsShown={showFeedback}>
            <VerticalAnswerWrapper>
              {multipleSelect.potentialAnswers
                .filter(
                  (potentialAnswer) => !showFeedback || selectedAnswers.includes(potentialAnswer),
                )
                .map((potentialAnswer) => (
                  <Answer
                    variant="on-dark"
                    icon={
                      showFeedback ? (potentialAnswer.correct ? "checkbox" : "cross") : undefined
                    }
                    iconPosition="after"
                    key={`potential-answer-${potentialAnswer.id}`}
                    onClick={() => {
                      !showFeedback && toggleSelectedAnswer(potentialAnswer);
                    }}
                    buttonStatus={getButtonStatusMultipleSelect(
                      potentialAnswer.id,
                      selectedAnswers,
                      correctAnswers,
                      showFeedback,
                    )}
                  >
                    {decodeHtmlEntities(potentialAnswer.answer)}
                  </Answer>
                ))}
              {showFeedback &&
                selectedCorrectAnswers > 0 &&
                selectedCorrectAnswers < correctAnswers.length && (
                  <Answer
                    variant="on-dark"
                    data-test="answer"
                    buttonStatus={AnswerButtonStatus.ANSWER_IS_MISSING}
                  >
                    Niet alle juiste antwoorden geselecteerd
                  </Answer>
                )}
            </VerticalAnswerWrapper>

            {showCheckAnswerAndResetButton && !showFeedback && (
              <CheckOutAnswerAndRetryButton
                onClickRetryButton={resetAnswer}
                onClickShowAnswerButton={() => setShowFeedback(true)}
              />
            )}

            {showFeedback && (
              <Feedback
                feedback={multipleSelect.feedback}
                isCorrectAnswer={isSelectedAnswerCorrect()}
                correctAnswer={correctAnswers.map((answer) => answer.answer)}
                contentType={ContentTypes.MULTIPLE_SELECT}
              />
            )}
          </ScrollableGridItem>
        </StyledGrid>
      </ContentWrapper>
    </>
  );
};

export default Content;
