import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "../../../theme/custom-variables";

const subTitleStyling = ({ theme }: DefaultThemeProps) => css`
  color: ${customVariables.colors.subTitle};
  font-size: 1.178rem;
  line-height: 1;

  @media screen and (orientation: landscape) and (max-width: ${pxToRem(
      theme.viewportBreakpoint.md,
    )}) {
    display: none;
  }
`;

export const SubTitle = styled.p`
  ${subTitleStyling}
`;
