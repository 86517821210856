import { Coordinates } from "@dnd-kit/utilities";
import styled from "styled-components";

import { StyledIcon } from "../../Draggable/styles/styledIcon.styled";
import { StyledNumber } from "../../Draggable/styles/styledNumber.styled";

type StyledDroppableProps = {
  coordinates: Coordinates;
  isOver: boolean;
  hasDraggable: boolean;
};

export const StyledDroppable = styled.div.attrs(
  ({ coordinates, isOver, hasDraggable }: StyledDroppableProps) => ({
    style: {
      top: `${coordinates.y}px`,
      left: `${coordinates.x}px`,
      background: `rgba(216, 115, 31, ${isOver || hasDraggable ? 0.5 : 0.25})`,
    },
  }),
)<StyledDroppableProps>`
  box-sizing: content-box;
  border: solid 3px rgba(216, 115, 31, 1);
  color: white;
  text-shadow: 0 1px 0 dimgrey;
  text-align: center;
  border-radius: 50%;
  height: clamp(44px, 3vw + 1rem, 60px);
  width: clamp(44px, 3vw + 1rem, 60px);
  position: absolute;
  cursor: pointer;
  transform: translate(-50%, -50%);
  user-select: none;
  touch-action: none;

  ${StyledNumber}, ${StyledIcon} {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: clamp(16px, 2vw + 1rem, 50px);
  }
`;
