import styled, { css } from "styled-components";

import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

const contentHeaderStyled = ({ theme }: DefaultThemeProps) => css`
  font-family: "ClanPro Compressed Bold", Arial, sans-serif;
  font-size: 2rem;
  line-height: 2rem;
  color: ${theme.colors.base.textBody};
  margin: 0 0 1.6rem;
`;

export const StyledHeader = styled.h2`
  ${contentHeaderStyled}
`;
