import React, { FunctionComponent } from "react";
import { createPortal } from "react-dom";

import { SpinnerWrapper } from "@components/Activity/styles/activity.styled";
import CenteredSpinner from "@components/CenteredSpinner";
import { AnwbPlayer } from "@components/Player/Player/AnwbPlayer";
import {
  MobileBackButton,
  MobileNavigationButtonWrapper,
  MobileNextButton,
  NavigationButtonWrapper,
  PlayerContainer,
  PlayerWrapper as PlayerWrapperStyledComponent,
} from "@components/Player/styles/Player.styles";
import { NavigationButton } from "@components/buttons";
import usePlayLearningObject from "@hooks/usePlayLearningObject";
import {
  EventSystem,
  ServerSideEventDispatcherProvider,
} from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import PlayerWrapper from "src/components/Player/PlayerWrapper";

interface Props {
  learningObjectId: string;
  pageIndex: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
  h5pInstanceMetadata: Record<string, string>;
}

const Player: FunctionComponent<Props> = ({
  learningObjectId,
  pageIndex,
  onPreviousClick,
  onNextClick,
  h5pInstanceMetadata,
}) => {
  const { data: learningObject } = usePlayLearningObject(learningObjectId);

  if (!learningObject) {
    return (
      <SpinnerWrapper>
        <CenteredSpinner />
      </SpinnerWrapper>
    );
  }

  const hasPreviousPage = pageIndex !== 0;

  const eventBaseData: Record<string, string> = {
    ...h5pInstanceMetadata,
    learningObjectId,
  };

  return (
    <>
      <PlayerContainer data-testid="learning-object-player">
        <PlayerWrapperStyledComponent>
          <PlayerWrapper contentType={learningObject.type}>
            <ServerSideEventDispatcherProvider
              type={EventSystem.ANWB}
              eventBaseData={eventBaseData}
            >
              <AnwbPlayer
                contentType={learningObject.type}
                contentTypeOptions={learningObject.content}
              />
            </ServerSideEventDispatcherProvider>
          </PlayerWrapper>
          <NavigationButtonWrapper>
            {hasPreviousPage && (
              <NavigationButton $variant="previous" icon="arrow-left" onClick={onPreviousClick} />
            )}
            <NavigationButton
              data-testid="next-page-button"
              icon="arrow-right"
              $variant="next"
              onClick={onNextClick}
            />
          </NavigationButtonWrapper>
        </PlayerWrapperStyledComponent>
      </PlayerContainer>
      {createPortal(
        <MobileNavigationButtonWrapper>
          {hasPreviousPage && (
            <MobileBackButton icon="arrow-left" $variant="previous" onClick={onPreviousClick} />
          )}
          <MobileNextButton icon="arrow-right" $variant="next" onClick={onNextClick} />
        </MobileNavigationButtonWrapper>,
        document.body,
      )}
    </>
  );
};

export default Player;
