import styled, { css } from "styled-components";

import { ButtonPrimary } from "@anwb/poncho/components/button";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "../../theme/custom-variables";

const buttonPrimaryStyles = ({ theme }: DefaultThemeProps) => css`
  && {
    border-radius: 2rem;

    &:hover {
      color: ${customVariables.colors.textButtonInverted};
    }

    &:active,
    &:focus {
      color: ${theme.colors.buttons.textButtonPrimary};
    }
  }
`;

export default styled(ButtonPrimary).attrs({
  variant: "on-dark",
})`
  ${buttonPrimaryStyles};
`;
