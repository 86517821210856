import { customVariables } from "content-types-shared/src/theme/custom-variables";
import styled, { css, keyframes } from "styled-components";

import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import arrowBottomSvg from "../../../assets/arrow-bottom.svg";
import arrowDefaultSvg from "../../../assets/arrow-default.svg";
import correctAnswerSvg from "../../../assets/correct-answer.svg";
import wrongAnswerSvg from "../../../assets/wrong-answer.svg";
import { getAbsoluteUrlToAsset } from "../../../styling/index.styled";
import BodyLarge from "../../../typography/BodyLarge";
import SubtitleEmphasis from "../../../typography/SubtitleEmphasis";
import { AnswerWrapper } from "../../AnswerButton/styles/answerButton.styled";

const feedbackTitleImageStyled = () => css`
  content: "";
  position: absolute;
  width: 90px;
  height: 80px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-repeat: no-repeat;
`;

export const fadeIn = () => keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`;

export const CorrectFeedbackTitleStyled = styled(SubtitleEmphasis)`
  position: relative;
  animation: ${fadeIn} 300ms linear 240ms;
  animation-fill-mode: forwards;
  opacity: 0;
  margin: 1rem 0 2rem;
  color: ${customVariables.colors.correctAnswerTitle};

  &:after {
    ${feedbackTitleImageStyled};
    left: 100px;
    background-image: url("${getAbsoluteUrlToAsset(correctAnswerSvg)}");
  }
`;

export const WrongFeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const WrongFeedbackTitleStyled = styled(SubtitleEmphasis)`
  position: relative;
  animation: ${fadeIn} 300ms linear 240ms;
  animation-fill-mode: forwards;
  opacity: 0;
  margin: 1.5rem 0 2.5rem;
  color: ${customVariables.colors.wrongAnswerTitle};

  &:after {
    ${feedbackTitleImageStyled};
    left: 80px;
    background-image: url("${getAbsoluteUrlToAsset(wrongAnswerSvg)}");
  }
`;

const correctAnswerIsTitleStyled = ({ theme }: DefaultThemeProps) => css`
  position: relative;
  width: 10rem;
  color: ${theme.colors.base.textBody};
  display: flex;
  animation: ${fadeIn} 300ms linear 240ms;
  animation-fill-mode: forwards;
  opacity: 0;
`;

export const CorrectAnswerIsTitle = styled(SubtitleEmphasis)<{ arrowBottom: boolean }>`
  ${correctAnswerIsTitleStyled};
  ${({ arrowBottom }) =>
    arrowBottom
      ? `
      margin: 0 0 2.5rem;
      align-items: flex-start;
      flex-direction: column;
    `
      : `
      margin: 0;
      align-items: flex-end;
      justify-content: flex-start;`}
`;

const correctAnswerTextStyled = () => css`
  font-size: 1.4em !important;
  font-weight: bold !important;
  color: ${customVariables.colors.correctAnswerTitle};
`;

export const CorrectAnswerText = styled(BodyLarge)`
  ${correctAnswerTextStyled};
`;

export const CorrectAnswerWrapper = styled(AnswerWrapper)`
  margin: 1rem 0;
  animation: ${fadeIn} 300ms linear 240ms;
  animation-fill-mode: forwards;
  opacity: 0;
`;

export const Arrow = styled.div`
  background-image: url("${getAbsoluteUrlToAsset(arrowDefaultSvg)}");
  background-repeat: no-repeat;
  width: 5rem;
  animation: ${fadeIn} 300ms linear 300ms;
  animation-fill-mode: forwards;
  opacity: 0;
  padding-right: 5rem;
  height: 4.5rem;
  margin: 0.3rem 1rem 0.3rem 0.3rem;
`;
export const ArrowBottom = styled.div`
  position: absolute;
  width: 5rem;
  height: 4.5rem;
  bottom: -2.5rem;
  margin: 0.3rem 1rem 0.3rem 0.3rem;
  padding-right: 5rem;
  animation: ${fadeIn} 300ms linear 300ms;
  animation-fill-mode: forwards;
  background-image: url("${getAbsoluteUrlToAsset(arrowBottomSvg)}");
  background-repeat: no-repeat;
  z-index: -1;
`;
export const FeedbackWrapper = styled.div`
  margin: 0;
`;
