import { customVariables } from "content-types-shared/src/theme/custom-variables";
import styled, { css } from "styled-components";

import { FormContainerFieldWrapper } from "@anwb/poncho/components/form-container-field";
import GridItem from "@anwb/poncho/components/grid/GridItem";
import Icon from "@anwb/poncho/components/icon";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

export const CorrectAnswerIcon = styled(Icon).attrs(() => ({
  variant: "checkbox",
}))`
  color: ${customVariables.colors.correctAnswerButton};
`;

export const WrongAnswerIcon = styled(Icon).attrs(() => ({
  variant: "cross",
}))`
  color: ${customVariables.colors.wrongAnswerButton};
`;

const wrongAnswerStyling = () => css`
  color: white;
  background: ${customVariables.colors.wrongAnswerButtonTransparent};
  border: 2px solid ${customVariables.colors.wrongAnswerButton};
`;

const correctAnswerStyling = () => css`
  color: white;
  background-color: ${customVariables.colors.correctAnswerButtonTransparent};
  border: 2px solid ${customVariables.colors.correctAnswerButton};
`;

export enum InputStylesVariant {
  DEFAULT,
  IS_CORRECT,
  IS_WRONG,
}

interface InputProps {
  inputStylesVariant: InputStylesVariant;
}

const inputStyling = ({ theme, inputStylesVariant }: DefaultThemeProps & InputProps) => css`
  ${FormContainerFieldWrapper} {
    > div {
      border-radius: 2rem;
      width: 100%;
      margin: 0;
      border: none;
      padding: 0 1.71rem;
      transition: 0.15s ease-in-out;

      @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
        width: 100%;
        margin: 0;
      }

      ${inputStylesVariant === InputStylesVariant.IS_WRONG && wrongAnswerStyling};
      ${inputStylesVariant === InputStylesVariant.IS_CORRECT && correctAnswerStyling};
    }
  }
`;

export const FormFieldInputContainer = styled.div`
  ${inputStyling};
`;

const imageGridItemStyling = () => css`
  display: flex;

  img {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
`;

export const ImageGridItem = styled(GridItem)`
  ${imageGridItemStyling};
`;
