import { useEffect, useState } from "react";

import { useConfigContext } from "@components/ConfigLoader";
import { Client } from "@domain/generated/learning-record-openapi";
import { useAuth } from "oidc-react";
import { OpenAPIClientAxios } from "openapi-client-axios";

import openApiDocument from "@topgun/learning-record/openapi.yaml";

export default function useLearningApi() {
  const { learningRecordApiUrl } = useConfigContext();
  const [api, setApi] = useState<{ api: Client | undefined }>({ api: undefined });
  const auth = useAuth();

  useEffect(() => {
    if (learningRecordApiUrl && !auth.isLoading && auth.userData?.access_token) {
      setApi({
        api: new OpenAPIClientAxios({
          definition: openApiDocument,
          withServer: {
            url: learningRecordApiUrl,
          },
          axiosConfigDefaults: {
            headers: {
              "Cache-Control": "no-cache",
              Authorization: `Bearer ${auth.userData.access_token}`,
            },
          },
        }).initSync<Client>(),
      });
    }
  }, [learningRecordApiUrl, auth.isLoading, auth.userData?.access_token]);

  return api;
}
