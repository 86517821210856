// Based on variants on example image:
// https://merk.anwb.nl/media/?mediaId=B465FF96-52AE-4D98-A89D6BD63DA4D3FF
export enum BynderImage {
  Original = "original",
  Small = "Small",
  Medium = "Medium",
  Header3000 = "Header 3000",
  ContentAfb1520 = "Content Afb 1520",
  Web2240 = "Web 2240",
  Web1280 = "Web 1280",
  Web760 = "Web 760",
  Web640 = "Web 640",
  Web560 = "Web 560",
  Web380 = "Web 380",
  Web280 = "Web 280",
  Web140 = "Web 140",
  Tweet = "Tweet",
  LinkedIn = "LinkedIn",
  Linkpost = "Linkpost",
  Stories = "Stories",
  CarrouselTijdlijn = "Carrousel_Tijdlijn",
}

export function scaleBynderImage(url: string, bynderImage: BynderImage = BynderImage.Medium) {
  const regex = /^https:\/\/merk.anwb.nl\/m\/([0-9a-z]*)\/(?!.*\.svg)/;
  const matches = url.match(regex);
  if (matches === null) {
    return url;
  }
  const identifier = matches[1];
  const bynderImageFilename = bynderImage.replace(" ", "_");
  return `https://merk.anwb.nl/m/${identifier}/${bynderImageFilename}-image`;
}
