import { useEffect } from "react";

import { useClient } from "@components/ClientProvider";
import { useUser } from "@hooks/useUser";
import { getH5PQueryKey } from "@hooks/utils";
import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";

export default (nextContentId: string | undefined) => {
  const queryClient = useQueryClient();
  const { dataClient } = useClient();
  const user = useUser();

  useEffect(() => {
    if (!user || !nextContentId) {
      return;
    }

    queryClient
      .prefetchQuery({
        queryKey: getH5PQueryKey(nextContentId, user.accessToken),
        queryFn: async ({ signal }) => {
          return dataClient.getLearningObject(nextContentId, user.accessToken, signal);
        },
      })
      .catch((error) => {
        captureException(new Error(error as string));
      });
  }, [nextContentId, queryClient, dataClient, user]);
};
