import styled, { css } from "styled-components";

import Icon from "@anwb/poncho/components/icon";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

const iconStyling = ({ theme }: DefaultThemeProps) => css`
  color: ${theme.colors.blanc.textButton};
`;

export const StyledIcon = styled(Icon).attrs({
  variant: "checkbox",
})`
  ${iconStyling};
`;
