import styled from "styled-components";

export const DraggableWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const DraggableContainer = styled.div`
  height: 4rem;
  width: 4rem;
  border: 2px solid #f28200;
  background-color: #f2820026;
  border-radius: 50%;
  padding: 0;
  user-select: none;
  touch-action: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
