import { ReactNode, useState } from "react";

import {
  Answer,
  AnswerButtonStatus,
  VerticalAnswerWrapper,
} from "content-types-shared/src/components/AnswerButton/styles/answerButton.styled";
import { decodeHtmlEntities } from "content-types-shared/src/utils/decodeHtmlEntities";

import ButtonDarkTertiary from "../ButtonDarkTertiary";

import FeedbackText from "./FeedbackText";

import {
  Arrow,
  ArrowBottom,
  CorrectAnswerIsTitle,
  CorrectAnswerText,
  CorrectAnswerWrapper,
  CorrectFeedbackTitleStyled,
  FeedbackWrapper,
  WrongFeedbackTitleStyled,
  WrongFeedbackWrapper,
} from "./styles/feedback.styled";

export enum ContentTypes {
  MULTIPLE_CHOICE_QUESTION = "MULTIPLE_CHOICE_QUESTION",
  MULTIPLE_SELECT = "MULTIPLE_SELECT",
  YES_NO_QUESTION = "YES_NO_QUESTION",
  INPUT = "INPUT",
}

type FeedbackProps = {
  feedback: { description: string | ReactNode };
  isCorrectAnswer: boolean;
  correctAnswer?: string | string[];
  contentType?: ContentTypes;
};

function JoinArrayOfStrings(array: string[]) {
  return array.length > 1
    ? array.slice(0, -1).join(", ") + " & " + array[array.length - 1]
    : array[0];
}

const Feedback = ({ feedback, isCorrectAnswer, correctAnswer, contentType }: FeedbackProps) => {
  const [showCorrectAnswerWithFeedback, setShowCorrectAnswerWithFeedback] = useState(false);

  const getCorrectAnswer = (
    correctAnswer: string | string[],
    contentType: ContentTypes | undefined,
  ) => {
    switch (contentType) {
      case ContentTypes.MULTIPLE_CHOICE_QUESTION:
      case ContentTypes.MULTIPLE_SELECT:
      case ContentTypes.YES_NO_QUESTION:
        if (Array.isArray(correctAnswer)) {
          {
            return (
              <VerticalAnswerWrapper>
                {correctAnswer.map((answer) => (
                  <Answer
                    variant="on-dark"
                    icon="checkbox"
                    iconPosition="after"
                    data-test="answer"
                    buttonStatus={AnswerButtonStatus.ANSWER_IS_CORRECT}
                  >
                    {decodeHtmlEntities(answer)}
                  </Answer>
                ))}
              </VerticalAnswerWrapper>
            );
          }
        }
        return (
          <Answer
            variant="on-dark"
            icon="checkbox"
            iconPosition="after"
            data-test="answer"
            buttonStatus={AnswerButtonStatus.ANSWER_IS_CORRECT}
          >
            {decodeHtmlEntities(correctAnswer)}
          </Answer>
        );
      default:
        return Array.isArray(correctAnswer) ? (
          <CorrectAnswerText>{JoinArrayOfStrings(correctAnswer)}</CorrectAnswerText>
        ) : (
          <CorrectAnswerText>{correctAnswer}</CorrectAnswerText>
        );
    }
  };

  if (isCorrectAnswer) {
    return (
      <FeedbackWrapper>
        <CorrectFeedbackTitleStyled data-test="feedback-title">Goed zo!</CorrectFeedbackTitleStyled>
        <FeedbackText feedback={feedback} />
      </FeedbackWrapper>
    );
  }

  return (
    <FeedbackWrapper>
      {!showCorrectAnswerWithFeedback && (
        <>
          <WrongFeedbackWrapper onClick={() => setShowCorrectAnswerWithFeedback(true)}>
            <WrongFeedbackTitleStyled data-test="feedback-title">Helaas</WrongFeedbackTitleStyled>
            <ButtonDarkTertiary icon="eye">Bekijk de toelichting</ButtonDarkTertiary>
          </WrongFeedbackWrapper>
        </>
      )}

      {showCorrectAnswerWithFeedback && (
        <>
          {correctAnswer && (
            <>
              {contentType === ContentTypes.INPUT ? (
                <CorrectAnswerIsTitle arrowBottom={true}>
                  Het juiste antwoord
                  <ArrowBottom />
                </CorrectAnswerIsTitle>
              ) : (
                <CorrectAnswerIsTitle>
                  Het juiste antwoord
                  <Arrow />
                </CorrectAnswerIsTitle>
              )}

              <CorrectAnswerWrapper>
                {getCorrectAnswer(correctAnswer, contentType)}
              </CorrectAnswerWrapper>
            </>
          )}
          <FeedbackText feedback={feedback} />
        </>
      )}
    </FeedbackWrapper>
  );
};

export default Feedback;
