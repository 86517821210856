import styled, { css } from "styled-components";

import { PageTitle } from "@anwb/poncho/components/typography";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

const TitleStyling = ({ theme }: DefaultThemeProps) => css`
  color: ${theme.colors.base.textBody};
  margin: 0;
  font-size: 3.2rem;
  line-height: 1;
  font-family: "ClanPro Compressed Bold", Arial, sans-serif;
`;

export const TitleText = styled(PageTitle)<{ hideOnMobile?: boolean }>`
  ${TitleStyling}

  ${(props) =>
    props.hideOnMobile &&
    css`
      @media (max-width: ${({ theme }: DefaultThemeProps) =>
          pxToRem(theme.viewportBreakpoint.sm)}) {
        display: none;
      }
    `}
`;
