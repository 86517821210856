import Content from "./Content/Content";
import { VideoProperties } from "./types/types";

type VideoProps = {
  video: VideoProperties;
};

const Video = ({ video }: VideoProps) => {
  return <Content video={video} />;
};

export default Video;
