import { useMemo } from "react";

import { PotentialAnswer } from "content-types-shared/src/types/types";

import Content from "./Content/Content";
import { MultipleChoiceQuestion } from "./types/types";

type AppProps = {
  question: MultipleChoiceQuestion;
};

const McQuestion = ({ question }: AppProps) => {
  const lessonWithIds = useMemo(
    () => ({
      ...question,
      potentialAnswers: question.potentialAnswers?.map((potentialAnswer: PotentialAnswer) => ({
        ...potentialAnswer,
        id: Math.random().toString().substring(2),
      })),
    }),
    [question],
  );
  return lessonWithIds && <Content question={lessonWithIds} />;
};

export default McQuestion;
