import Content from "./Content/Content";
import { Lesson } from "./types/types";

type AppProps = {
  lesson: Lesson;
};

const LessonTheory = ({ lesson }: AppProps) => {
  return (
    lesson && (
      <div key={lesson.id}>
        <Content lesson={lesson} />
      </div>
    )
  );
};

export default LessonTheory;
