import { CSS, Transform } from "@dnd-kit/utilities";
import styled from "styled-components";

import { StyledNumber } from "./styledNumber.styled";

type DraggableButtonProps = {
  transform: Transform | null;
  isDragging: boolean;
  isHidden: boolean;
};

export const DraggableButton = styled.button.attrs(
  ({ transform, isHidden, isDragging }: DraggableButtonProps) => ({
    style: {
      transform: transform ? CSS.Translate.toString(transform) : "none",
      opacity: isHidden ? 0 : 1,
      pointerEvents: isHidden ? "none" : "auto",
      boxShadow: isDragging
        ? "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)"
        : "",
    },
  }),
)<DraggableButtonProps>`
  color: white;
  cursor: move;
  height: calc(4rem - 2px);
  width: calc(4rem - 2px);
  border: none;
  background-color: #f28200;
  border-radius: 50%;
  padding: 0;
  user-select: none;
  touch-action: none;
  position: relative;
  flex-shrink: 0;

  ${StyledNumber} {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 3rem;
  }
`;
