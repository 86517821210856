import styled from "styled-components";

import Typography from "@anwb/poncho/components/typography";

export const SubtitleEmphasis = styled(Typography).attrs(() => ({
  variant: "component-subtitle",
}))`
  text-transform: uppercase;
  && {
    font-family: "Bernina Sans Compressed H5P CT", "Bernina Sans Compressed", "Bernina Sans", Arial,
      sans-serif;
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 0.9;
  }
`;
export default SubtitleEmphasis;
