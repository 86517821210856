import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";

import CenteredSpinner from "@components/CenteredSpinner";
import useLearningApi from "@hooks/useLearningApi";
import { AnwbServerSideEventDispatcher } from "content-types-shared/src/event/dispatcher/AnwbServerSideEventDispatcher";
import { ServerSideEventDispatcher } from "content-types-shared/src/event/dispatcher/ServerSideEventDispatcher";
import { useAuth } from "oidc-react";

export enum EventSystem {
  H5P = "H5P",
  ANWB = "ANWB",
}

const ServerSideEventDispatcherContext = createContext<ServerSideEventDispatcher | undefined>(
  undefined,
);

interface ServerSideEventDispatcherProviderProps {
  type: EventSystem;
  eventBaseData: Record<string, string>;
  children: ReactNode;
}

export const ServerSideEventDispatcherProvider = ({
  type,
  eventBaseData,
  children,
}: ServerSideEventDispatcherProviderProps) => {
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const auth = useAuth();
  const { api: learningApi } = useLearningApi();

  useEffect(() => {
    if (!auth.isLoading && auth.userData?.id_token && auth.userData?.profile.sub) {
      setToken(auth.userData.id_token);
      setUserId(auth.userData.profile.sub);
    }
  }, [auth.isLoading, auth.userData?.id_token, auth.userData?.profile.sub]);

  const dispatcher = useMemo(() => {
    if (learningApi && token && userId) {
      switch (type) {
        case EventSystem.ANWB:
          return new AnwbServerSideEventDispatcher(learningApi, {
            ...eventBaseData,
            userId: userId,
            issuer: auth.userData!.profile.iss,
          } as ConstructorParameters<typeof AnwbServerSideEventDispatcher>[1]);
        default:
          throw new Error(`Unsupported type`);
      }
    }
    return undefined;
  }, [type, token, userId]);

  if (!dispatcher) {
    return <CenteredSpinner />;
  }

  return (
    <ServerSideEventDispatcherContext.Provider value={dispatcher}>
      {children}
    </ServerSideEventDispatcherContext.Provider>
  );
};

export const useServerSideEventDispatcher = (): ServerSideEventDispatcher => {
  const context = useContext(ServerSideEventDispatcherContext);

  if (!context) {
    throw new Error(
      "useServerSideEventDispatcher must be used within a ServerSideEventDispatcherProvider",
    );
  }

  return context;
};
