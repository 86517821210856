import { useEffect, useState } from "react";

import CheckOutAnswerAndRetryButton from "content-types-shared/src/components/CheckOutAnswerAndRefreshButton/CheckOutAnswerAndRetryButton";
import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Feedback, { ContentTypes } from "content-types-shared/src/components/Feedback/Feedback";
import HeaderText from "content-types-shared/src/components/HeaderText/HeaderText";
import { ScaledImage } from "content-types-shared/src/components/Image/ScaledImage";
import SubHeaderText from "content-types-shared/src/components/SubHeaderText/SubHeaderText";
import { ContentWrapper } from "content-types-shared/src/components/styles/contentWrapper.styled";
import {
  ImageGridItem,
  ScrollableGridItem,
  StyledGrid,
} from "content-types-shared/src/styling/grid.styled";

import FormFieldInput from "@anwb/poncho/components/form-field-input";

import { InputQuestion } from "../types/types";

import {
  CorrectAnswerIcon,
  FormFieldInputContainer,
  InputStylesVariant,
  WrongAnswerIcon,
} from "./styles";

type ContentProps = {
  inputQuestion: InputQuestion;
};

const Content = ({ inputQuestion }: ContentProps) => {
  const dispatcher = useServerSideEventDispatcher();
  const [userAnswer, setUserAnswer] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);

  const updateUserAnswer = (userAnswer: string) => {
    setUserAnswer(userAnswer);
    setShowFeedback(false);
  };

  useEffect(() => {
    setUserAnswer("");
    setShowFeedback(false);
  }, [inputQuestion]);

  const isCorrectAnswer = parseInt(userAnswer) === inputQuestion.inputAnswer;

  let inputStylesVariant = InputStylesVariant.DEFAULT;
  let rightIcon = undefined;

  if (showFeedback) {
    inputStylesVariant = isCorrectAnswer
      ? InputStylesVariant.IS_CORRECT
      : InputStylesVariant.IS_WRONG;
    rightIcon = isCorrectAnswer ? <CorrectAnswerIcon /> : <WrongAnswerIcon />;
  }

  const checkAnswer = () => {
    setShowFeedback(true);
    setUserAnswer(userAnswer);

    const score = isCorrectAnswer ? 1 : 0;
    dispatcher.dispatch({ score: score, maxScore: 1 });
  };

  const resetAnswer = () => {
    setUserAnswer("");
    setShowFeedback(false);
  };

  return (
    <>
      <ContentWrapper>
        <HeaderText title={inputQuestion.question} />
        <SubHeaderText text="Vul het juiste antwoord in" />
        <StyledGrid columns={{ sm: 1, md: 1, lg: 2 }} justify="start">
          <ImageGridItem>
            <ScaledImage imageUrl={inputQuestion.imageUrl} />
          </ImageGridItem>
          <ScrollableGridItem feedbackIsShown={showFeedback}>
            <FormFieldInputContainer inputStylesVariant={inputStylesVariant}>
              <FormFieldInput
                label=""
                reference="input"
                name="input"
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  updateUserAnswer(numericValue);
                }}
                right={rightIcon}
                placeholder="Vul hier het juiste antwoord in in cijfers (0-9)"
                value={userAnswer}
                autoComplete="off"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                textValidationMessages={{
                  patternMismatch: "Je hebt dit veld onjuist ingevuld. Gebruik alleen cijfers.",
                }}
                onKeyDown={(e) => {
                  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
                  if (!allowedKeys.includes(e.key) && (e.key < "0" || e.key > "9")) {
                    e.preventDefault();
                  }
                }}
                disabled={showFeedback && true}
              />
            </FormFieldInputContainer>

            {userAnswer.length > 0 && (
              <>
                {!showFeedback && (
                  <CheckOutAnswerAndRetryButton
                    onClickRetryButton={resetAnswer}
                    onClickShowAnswerButton={checkAnswer}
                  />
                )}
                {showFeedback && (
                  <Feedback
                    feedback={inputQuestion.feedback}
                    isCorrectAnswer={isCorrectAnswer}
                    correctAnswer={inputQuestion.inputAnswer.toString()}
                    contentType={ContentTypes.INPUT}
                  />
                )}
              </>
            )}
          </ScrollableGridItem>
        </StyledGrid>
      </ContentWrapper>
    </>
  );
};

export default Content;
