import styled, { css } from "styled-components";

import Notification, { NotificationContent } from "@anwb/poncho/components/notification";

import { customVariables } from "../../../theme/custom-variables";

const notificationStyling = () => css`
  ${NotificationContent} {
    color: ${customVariables.colors.textButtonInverted};
  }
  width: calc(100% - 4rem);
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;

  h5 {
    margin-top: 0;
  }
`;

export const NotificationStyled = styled(Notification)`
  ${notificationStyling}
`;
