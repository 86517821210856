import Content from "./Content/Content";
import { CarouselContent } from "./types/types";

type CarouselProperties = {
  carousel: CarouselContent;
};

const Carousel = ({ carousel }: CarouselProperties) => {
  return carousel && <Content carousel={carousel} />;
};

export default Carousel;
