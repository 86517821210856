import { create } from "zustand";

type ContentTypeStore = {
  playing: boolean | undefined;
  showDropzones: boolean | undefined;
  userAnswer: number | undefined;
  introHasPlayed: boolean | undefined;
  endAnimation: number | undefined;

  setPlaying: (playing: boolean) => void;
  setShowDropzones: (showDropzones: boolean) => void;
  setUserAnswer: (userAnswers: any) => void;
  setIntroHasPlayed: (introHasPlayed: boolean) => void;
  setEndAnimation: (endAnimation: number | undefined) => void;
  reset: () => void;
};
const initialState = {
  playing: true,
  showDropzones: false,
  userAnswer: undefined,
  introHasPlayed: false,
  endAnimation: undefined,
};
export const useContentTypeStore = create<ContentTypeStore>((set) => ({
  ...initialState,
  setPlaying: (playing: boolean) => set({ playing }),
  setShowDropzones: (showDropzones: boolean) => set({ showDropzones }),
  setUserAnswer: (userAnswer: any) => set({ userAnswer }),
  setIntroHasPlayed: (introHasPlayed: boolean) => set({ introHasPlayed }),
  setEndAnimation: (endAnimation: number | undefined) => set({ endAnimation }),
  reset: () => set(initialState),
}));
