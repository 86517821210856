import styled, { css } from "styled-components";

import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import BodyLarge from "../../../typography/BodyLarge";

import { fadeIn } from "./feedback.styled";

const feedbackStyled = ({ theme }: DefaultThemeProps) => css`
  text-align: left;
  color: ${theme.colors.base.textBody};
  animation: ${fadeIn} 300ms linear 240ms;
  opacity: 0;
  animation-fill-mode: forwards;
  width: 100%;

  p[style] {
    text-align: center !important;
  }

  p {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    text-align: left;
  }
`;

export const FeedbackContent = styled(BodyLarge).attrs({
  tagName: "div",
})`
  ${feedbackStyled}
`;
