import { useDroppable } from "@dnd-kit/core";
import { Coordinates } from "@dnd-kit/utilities";

import { StyledIcon } from "../Draggable/styles/styledIcon.styled";

import { StyledDroppable } from "./styles/droppable.styled";

type DroppableType = {
  coordinates: Coordinates;
  option: number;
  userAnswer?: number;
  onClick: (event: any) => void;
};

export const DroppableSingle = ({ coordinates, option, userAnswer, onClick }: DroppableType) => {
  const { setNodeRef, isOver } = useDroppable({ id: "droppable" + option });
  const showUserAnswer = userAnswer === option;

  return (
    <StyledDroppable
      ref={setNodeRef}
      id={"droppable" + option}
      onClick={onClick}
      coordinates={coordinates}
      isOver={isOver}
      hasDraggable={showUserAnswer}
    >
      {showUserAnswer && <StyledIcon />}
    </StyledDroppable>
  );
};
