import { useCallback, useEffect, useState } from "react";

import { getButtonStatus } from "content-types-shared/src/components/AnswerButton/getButtonStatus";
import {
  Answer,
  VerticalAnswerWrapper,
} from "content-types-shared/src/components/AnswerButton/styles/answerButton.styled";
import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Feedback, { ContentTypes } from "content-types-shared/src/components/Feedback/Feedback";
import HeaderText from "content-types-shared/src/components/HeaderText/HeaderText";
import { ScaledImage } from "content-types-shared/src/components/Image/ScaledImage";
import Notification from "content-types-shared/src/components/Notification/Notification";
import SubHeaderText from "content-types-shared/src/components/SubHeaderText/SubHeaderText";
import { ContentWrapper } from "content-types-shared/src/components/styles/contentWrapper.styled";
import {
  ImageGridItem,
  ScrollableGridItem,
  StyledGrid,
} from "content-types-shared/src/styling/grid.styled";
import { PotentialAnswer } from "content-types-shared/src/types/types";
import { decodeHtmlEntities } from "content-types-shared/src/utils/decodeHtmlEntities";

import { CountdownTimer } from "../CountdownTimer/CountdownTimer";
import { MultipleChoiceQuestion } from "../types/types";

import { CountdownTimerWrapper } from "./styles";

type ContentProps = {
  question: MultipleChoiceQuestion;
};

const Content = ({ question }: ContentProps) => {
  const MAX_SCORE = 1;
  const dispatcher = useServerSideEventDispatcher();
  const [selectedAnswer, setSelectedAnswer] = useState<PotentialAnswer>();
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  useEffect(() => {
    setSelectedAnswer(undefined);
    setIsTimerRunning(true);
  }, [question]);

  const onCountDownComplete = useCallback(() => {
    dispatcher.dispatch({ score: 0, maxScore: MAX_SCORE });

    setSelectedAnswer({ id: "1", answer: "Time has expired", correct: false });
  }, [setSelectedAnswer, dispatcher]);

  const correctAnswer = question.potentialAnswers.find((answer) => answer.correct);
  if (correctAnswer === undefined) {
    return (
      <Notification
        variant="warning"
        title="Deze vraag kan niet getoond worden vanwege een onvolledige configuratie"
      >
        Tip voor auteur: geef aan welk antwoord correct is.
      </Notification>
    );
  }

  const answerButtonClickHandler = (potentialAnswer: PotentialAnswer) => {
    setSelectedAnswer(potentialAnswer);
    setIsTimerRunning(false);

    const score = potentialAnswer.correct ? MAX_SCORE : 0;
    dispatcher.dispatch({ score, maxScore: MAX_SCORE });
  };

  return (
    <>
      <ContentWrapper>
        <HeaderText title={question.question} />
        <SubHeaderText text="Selecteer het juiste antwoord" />
        {question.setTimer && isTimerRunning && (
          <CountdownTimerWrapper>
            <CountdownTimer duration={8} onComplete={onCountDownComplete} />
          </CountdownTimerWrapper>
        )}
        <StyledGrid columns={{ sm: 1, md: 1, lg: 2 }} justify="start">
          <ImageGridItem>
            <ScaledImage imageUrl={question.imageUrl} />
          </ImageGridItem>
          <ScrollableGridItem feedbackIsShown={selectedAnswer !== undefined}>
            <VerticalAnswerWrapper>
              {question.potentialAnswers.map(
                (potentialAnswer) =>
                  (!selectedAnswer || selectedAnswer.id === potentialAnswer.id) && (
                    <Answer
                      variant="on-dark"
                      icon={
                        selectedAnswer ? (selectedAnswer.correct ? "checkbox" : "cross") : undefined
                      }
                      iconPosition="after"
                      key={`potential-answer-${potentialAnswer.id}`}
                      onClick={() => answerButtonClickHandler(potentialAnswer)}
                      data-test="multiple-choice-answer"
                      buttonStatus={getButtonStatus(potentialAnswer, selectedAnswer)}
                    >
                      {decodeHtmlEntities(potentialAnswer.answer)}
                    </Answer>
                  ),
              )}
            </VerticalAnswerWrapper>

            {selectedAnswer && (
              <Feedback
                feedback={question.feedback}
                isCorrectAnswer={selectedAnswer.correct}
                correctAnswer={correctAnswer.answer}
                contentType={ContentTypes.MULTIPLE_CHOICE_QUESTION}
              />
            )}
          </ScrollableGridItem>
        </StyledGrid>
      </ContentWrapper>
    </>
  );
};

export default Content;
