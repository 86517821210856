export const customVariables = {
  colors: {
    textButtonInverted: "#01142c",
    correctAnswerTitle: "#40a261",
    wrongAnswerTitle: "#d1413b",
    correctAnswerButton: "#40a261",
    correctAnswerButtonTransparent: "#40A26140",
    wrongAnswerButton: "#d1413b",
    wrongAnswerButtonTransparent: "#D1413B40",
    rightAnswerShownInFeedback: "#9747ff",
    selectedAnswerBackground: "#000f22",
    selectedAnswerBorder: "#000f22",
    backgroundBlue: "#001B3C",
    swiperScrollbar: "#606060",
    swiperSlideBorder: "#00569D",
    swiperSlideImageBackground: "#09264a",
    subTitle: "#0096DA",
    bodyBackground: "#000f22",
    correctAnswerBorder: "#40a261",
    wrongAnswerBorder: "#d1413b",
  },
};
