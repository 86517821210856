import { UnknownThemePartial } from "@anwb/poncho/design-tokens/theme";

export const ANWBDarkBrandRefreshThemePartial: UnknownThemePartial = {
  borderRadius: {
    m: "15",
  },
  colors: {
    base: {
      textTitles: `#ffffff`,
      textTitleLink: `#ffffff`,
      textSubtitles: `#ffffff`,
      textBody: `#ffffff`,
      textLabel: `#ffffff`,
      textDescription: `#ffffff`,
      textSticker: `#ffffff`,
      textButton: `#ffffff`,
      textTag: `#ffffff`,
      textFormFieldInput: `#000307`,
      textMainNavigation: `#ffffff`,
      textTopbarNavigation: `#ffffff`,
      textFooterBar: `#ffffff`,
      textSupport: `#ffffff`,
      iconButton: `#ffffff`,
      textCounter: `#ffffff`,
      textIntro: `#ffffff`,
      bgBlocksPrimaryHover: `#003472`,
      textNode: `#ffffff`,
      overlay: `rgba(0, 7, 11, 0.4)`,
    },
    buttons: {
      bgButtonPrimary: "#ffffff",
      textButtonPrimary: "#01142c",
      bgButtonPrimaryHover: "#e5e5e5",
      bgButtonPrimaryActive: "#e5e5e5",
    },
    highlight: {
      textHover: `#ffffff`,
      iconInteractive: "#ffffff",
      iconHover: `#aaa`,
      textTabHover: `#0096da`,
      underlinePaginationHover: `#0096da`,
    },
    blanc: {
      textLinkInverse: `#ffffff`,
      textLinkUnderlined: `#ffffff`,
      textTooltip: `#ffffff`,
      bgModal: "#001B3C",
    },
    light: {
      borderReceipt: `#e5e5e5`,
      bgBlocksSecondaryHover: `#ececec`,
      borderPanelInformative: `#002857`,
    },
    extralight: {
      bgTicket: `#f2f2f2`,
      bgNavigationMainLabelActive: `#e8f2f8`,
    },
    gradients: {
      // Button background primary decide
      buttonPrimaryDecide: `#ffffff`,
      // Hover button primary color
      bgButtonPrimaryDecideHover: `#ffffff`,
      bgButtonPrimaryDecideActive: `#ffffff`,
    },
    positive: {
      // Notification positive
      titleNotification: `#207100`,
      // Notification positive
      bgNotification: `#eef5eb`,
      // Notification positive
      borderNotification: `#207100`,
      iconCheck: `#207100`,
      iconUsp: `#000307`,
      iconNotification: `#207100`,
      // Positive feedback
      borderFormField: `#007000`,
      // Positive feedback
      textSupport: `#007000`,
      textPrice: `#207100`,
    },
  },
};
