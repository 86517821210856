import { FunctionComponent, createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Notification from "@components/Notification";
import { captureException } from "@sentry/react";

interface ExternalConfig {
  apiUrl: string;
  learningRecordApiUrl: string;
  registerUrl: string;
}

type ExternalConfigModule = { default: ExternalConfig };

function isModule(val: unknown): val is { default: unknown } {
  return typeof val === "object" && val !== null && "default" in val;
}
function isConfig(val: unknown): val is ExternalConfigModule {
  if (!isModule(val)) {
    return false;
  }
  const { default: conf } = val;
  return typeof conf === "object" && conf !== null && "apiUrl" in conf;
}

const load: () => Promise<ExternalConfig> = () => {
  // eslint-disable-next-line import/no-absolute-path
  return import(/* webpackIgnore: true */ "/config.js" as string).then((module) => {
    if (isConfig(module)) {
      return module.default;
    }
    throw new Error("Failed to load");
  });
};

const initialConfig: ExternalConfig = {
  apiUrl: "",
  learningRecordApiUrl: "",
  registerUrl: "",
};

const Context = createContext<ExternalConfig>(initialConfig);
export const useConfigContext = () => useContext(Context);

export const ConfigLoader: FunctionComponent = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [failed, setFailed] = useState(false);
  const [config, setConfig] = useState(initialConfig);
  const { t } = useTranslation();

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const externalConfig = await load();
        // eslint-disable-next-line no-console
        console.debug("using configuration", externalConfig);
        setConfig(externalConfig);
      } catch (err) {
        captureException(new Error(err as string));
        setFailed(true);
      } finally {
        setReady(true);
      }
    };

    void loadConfig();
  }, []);

  if (!ready) {
    return <></>;
  }
  if (failed) {
    return (
      <Notification
        $alignNotification
        variant="error"
        title={t("notifications.configurationLoadingError.title")}
      >
        {t("notifications.configurationLoadingError.text")}
      </Notification>
    );
  }
  return <Context.Provider value={config}>{children}</Context.Provider>;
};
