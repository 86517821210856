import { useContext } from "react";

import { ThemeContext } from "styled-components";

import { getApplicationSize } from "@anwb/poncho/utilities/browser";

import { scaleBynderImage } from "../../utils/image";

import { StyledScaledImage } from "./styles/scaledImage.styled";

export const ScaledImage = ({ imageUrl, className }: { imageUrl: string; className?: string }) => {
  const theme = useContext(ThemeContext);

  if (imageUrl) {
    const src = scaleBynderImage(imageUrl);

    const applicationSize = getApplicationSize(theme);
    const imageWidth = applicationSize === "small" ? undefined : "half";

    return (
      <StyledScaledImage
        className={className}
        src={src}
        alt="Image supporting the question"
        imageWidth={imageWidth}
        decoding="sync"
      />
    );
  }

  return null;
};
