import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CallToActionBlock from "@components/CallToActionBlock";
import FullscreenChapterCard from "@components/FullscreenChapterCard/FullscreenChapterCard";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import NextChapterCard from "@components/NextChapterCard";
import TrainerCard from "@components/TrainerCard";
import TrainerGrid from "@components/TrainerGrid";
import useChapters from "@hooks/useChapters";
import useCourse from "@hooks/useCourse";
import { useCourseWithCTA } from "@hooks/useCourseWithCTA";
import useDynamicActivityConfigurations from "@hooks/useDynamicActivityConfigurations";
import useRecentAccessedPage from "@hooks/useRecentAccessedPage";

import HorizontalScroll from "@anwb/poncho/components/horizontal-scroll";

import { DynamicActivityConfigurationType } from "@topgun/shared/src/types/frontend";

import {
  AlignRight,
  DashboardContainer,
  DashboardGrid,
  DashboardGridHeader,
  DashboardHeaderLink,
  DashboardLink,
  DashboardSubTitle,
  DashboardTitle,
  DashboardTitleHeader,
  DashboardWrapper,
  PracticeExamWrapper,
  WhatsNextGridItem,
} from "./styles";

const Dashboard: FunctionComponent = () => {
  const navigate = useNavigate();
  const { setShowHeaderMenu, clearNavigateBack } = useHeaderUpdater();
  const { t } = useTranslation();
  const { course } = useCourse();
  const { chapters } = useChapters();
  const { data: recentAccessedPage } = useRecentAccessedPage();
  const {
    isLoading: isLoadingTrainers,
    error: errorLoadingTrainers,
    dynamicActivityConfigurations: allTrainers = [],
  } = useDynamicActivityConfigurations(
    [DynamicActivityConfigurationType.TRAINER, DynamicActivityConfigurationType.PRACTICE_EXAM],
    course?.value.id,
  );
  const hasChapters = chapters && chapters.length > 0;
  const isCourseWithCTA = useCourseWithCTA();

  useEffect(() => {
    setShowHeaderMenu(true);
  }, [setShowHeaderMenu]);

  useEffect(() => {
    clearNavigateBack();
  }, [clearNavigateBack]);

  const handleGoToChapterOverview = () => {
    navigate("/chapters");
  };

  const handleGoToTrainers = () => {
    navigate("/trainers");
  };

  const nextChapters = useMemo(() => {
    if (!chapters || !recentAccessedPage?.chapter) {
      return [];
    }

    const foundIndex = chapters.findIndex(
      (chapter) => chapter.value.id === recentAccessedPage.chapter.value.id,
    );

    return foundIndex >= 0 ? chapters.slice(foundIndex + 1, chapters.length).slice(0, 2) : [];
  }, [chapters, recentAccessedPage?.chapter]);

  const practiceExam = allTrainers.find(
    (trainer) => trainer.type === DynamicActivityConfigurationType.PRACTICE_EXAM.toString(),
  );
  const trainers = allTrainers.filter(
    (trainer) => trainer.type !== DynamicActivityConfigurationType.PRACTICE_EXAM.toString(),
  );

  return (
    <DashboardWrapper>
      {recentAccessedPage && <FullscreenChapterCard recentAccessedPage={recentAccessedPage} />}
      <DashboardContainer data-testid="dashboard">
        <DashboardGridHeader>
          <DashboardTitleHeader>
            <DashboardTitle>{t("dashboard.chapters.title")}</DashboardTitle>
            <DashboardSubTitle>{t("dashboard.chapters.subtitle")}</DashboardSubTitle>
          </DashboardTitleHeader>
        </DashboardGridHeader>
        <DashboardGrid data-testid="dashboard__theorie">
          {nextChapters && (
            <HorizontalScroll dataTest="horizontal-scroll" slidesPerView={{ sm: 1, md: 1, lg: 2 }}>
              {nextChapters.map((nextChapter, index) => (
                <HorizontalScroll.Item key={`slide-${nextChapter.value.id}`}>
                  <WhatsNextGridItem
                    data-testid={`next-chapter-${nextChapter.value.id}`}
                    key={nextChapter.value.id}
                  >
                    <NextChapterCard
                      nextChapter={nextChapter}
                      animate
                      animateDelay={(index + 1) * 300}
                    />
                  </WhatsNextGridItem>
                </HorizontalScroll.Item>
              ))}
            </HorizontalScroll>
          )}
        </DashboardGrid>
        {hasChapters && (
          <AlignRight>
            <DashboardHeaderLink onClick={handleGoToChapterOverview}>
              {t("dashboard.chapters.buttonLabel")}
            </DashboardHeaderLink>
          </AlignRight>
        )}

        <DashboardGrid data-test="dashboard__trainers">
          <DashboardGridHeader>
            <DashboardTitleHeader>
              <DashboardTitle>{t("dashboard.trainers.title")}</DashboardTitle>
              <DashboardSubTitle>{t("dashboard.trainers.subtitle")}</DashboardSubTitle>
            </DashboardTitleHeader>
          </DashboardGridHeader>
        </DashboardGrid>
        {trainers && (
          <>
            <TrainerGrid
              isLoading={isLoadingTrainers}
              error={errorLoadingTrainers}
              trainers={trainers}
              columns={{ sm: 1, md: 2, lg: 3 }}
              count={3}
            />

            <AlignRight>
              <DashboardLink onClick={handleGoToTrainers}>
                {t("dashboard.trainers.overviewCta")}
              </DashboardLink>
            </AlignRight>
          </>
        )}

        {practiceExam && (
          <PracticeExamWrapper>
            <DashboardGridHeader>
              <DashboardTitleHeader>
                <DashboardTitle>{t("dashboard.practiceExam.title")}</DashboardTitle>
                <DashboardSubTitle>{t("dashboard.practiceExam.subtitle")}</DashboardSubTitle>
              </DashboardTitleHeader>
            </DashboardGridHeader>

            <TrainerCard
              data-testid="practice-exam-card"
              wide
              isPracticeExamButton
              trainer={practiceExam}
            />
          </PracticeExamWrapper>
        )}

        {isCourseWithCTA && <CallToActionBlock containerSize={"large"} />}
      </DashboardContainer>
    </DashboardWrapper>
  );
};

export default Dashboard;
