import { useDroppable } from "@dnd-kit/core";
import { Coordinates } from "@dnd-kit/utilities";

import { StyledNumber } from "../Draggable/styles/styledNumber.styled";

import { StyledDroppable } from "./styles/droppable.styled";

type DroppableType = {
  coordinates: Coordinates;
  option: number;
  userAnswer?: string;
  onClick: (event: any) => void; // TODO
};

export const DroppableMultiple = ({ coordinates, option, userAnswer, onClick }: DroppableType) => {
  const { setNodeRef, isOver } = useDroppable({ id: "droppable" + option });
  const showUserAnswer = userAnswer !== undefined ? parseInt(userAnswer, 10) + 1 : "";

  return (
    <StyledDroppable
      ref={setNodeRef}
      id={"droppable" + option}
      onClick={!showUserAnswer ? onClick : undefined}
      coordinates={coordinates}
      isOver={isOver}
      hasDraggable={!!showUserAnswer}
    >
      <StyledNumber>{showUserAnswer}</StyledNumber>
    </StyledDroppable>
  );
};
