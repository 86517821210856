import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import type { OnProgressProps } from "react-player/types/base";

import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { Coordinates } from "@dnd-kit/utilities";
import CheckOutAnswerAndRetryButton from "content-types-shared/src/components/CheckOutAnswerAndRefreshButton/CheckOutAnswerAndRetryButton";
import { DraggableSingle } from "content-types-shared/src/components/Draggable/DraggableSingle";
import { DroppableSingle } from "content-types-shared/src/components/Droppable/DroppableSingle";
import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Feedback from "content-types-shared/src/components/Feedback/Feedback";
import { InteractiveWrapper } from "content-types-shared/src/components/styles/imageContainer.styled";
import useResizeObserver from "content-types-shared/src/hooks/useResizeObserver";
import useResponsiveBynderVideo from "content-types-shared/src/hooks/useResponsiveBynderVideo";
import { DraggableContainer } from "content-types-shared/src/styling/draggable.styled";
import {
  ImageGridItem,
  ScrollableGridItem,
  StyledGrid,
} from "content-types-shared/src/styling/grid.styled";
import { decodeHtmlEntities } from "content-types-shared/src/utils/decodeHtmlEntities";

import { useContentTypeStore } from "../store/useContentTypeStore";
import { DragAndDropQuestion } from "../types/types";

import { ImageOnPause, ReactPlayerWrapper, StyledReactPlayer } from "./styles/movie.styled";

type MovieProps = {
  question: DragAndDropQuestion;
};

const Movie = ({ question }: MovieProps) => {
  const dispatcher = useServerSideEventDispatcher();
  const [showFeedback, setShowFeedback] = useState(false);
  const videoUrl = useResponsiveBynderVideo(question.videoUrl);
  const playerRef = useRef<ReactPlayer>(null);
  const playerWrapperRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver(playerWrapperRef);
  const resizeRatio =
    width !== undefined
      ? Math.round((width / question.resolution.width + Number.EPSILON) * 100) / 100
      : 1;

  const { playing, introHasPlayed, endAnimation, userAnswer, showDropzones } = useContentTypeStore(
    (state) => ({
      playing: state.playing,
      userAnswer: state.userAnswer,
      introHasPlayed: state.introHasPlayed,
      endAnimation: state.endAnimation,
      showDropzones: state.showDropzones,
    }),
  );
  const showCheckAnswer = userAnswer !== undefined;

  const { setPlaying, setUserAnswer, setShowDropzones, setIntroHasPlayed, setEndAnimation, reset } =
    useContentTypeStore();

  useEffect(() => {
    setShowFeedback(false);
    reset();
  }, [question]);

  const handleError = (message: string) => {
    console.error(message);
  };

  const progress = ({ playedSeconds }: OnProgressProps) => {
    if (introHasPlayed === false && playedSeconds >= question.timestampPause) {
      setIntroHasPlayed(true);
      setPlaying(false);
      setShowDropzones(true);
    }
    if (endAnimation !== undefined && playedSeconds >= endAnimation) {
      setPlaying(false);
    }
  };

  const checkAnswer = () => {
    const score = question.potentialAnswers[userAnswer!].correct ? 1 : 0;
    dispatcher.dispatch({ score: score, maxScore: 0 });

    setShowFeedback(true);
    playerRef.current?.seekTo(question.potentialAnswers[userAnswer!].timestampStart);
    setUserAnswer(userAnswer);
    setPlaying(true);
    setShowDropzones(false);
    setEndAnimation(question.potentialAnswers[userAnswer!].timestampStop);
  };

  const resetAnswer = () => {
    if (!playerRef?.current) {
      return;
    }
    setShowFeedback(false);
    setPlaying(false);
    playerRef.current.seekTo(question.timestampPause);
    setShowDropzones(true);
    setUserAnswer(undefined);
    setIntroHasPlayed(true);
  };

  const endDrag = (onDragEndEvent: DragEndEvent) => {
    if (onDragEndEvent.over !== null) {
      const droppableId = (onDragEndEvent.over.id as string).replace("droppable", "");
      setUserAnswer(parseInt(droppableId));
    }
  };

  const clickDroppable = (event: any) => {
    const droppableId = event.currentTarget.id;
    const droppableNumber = droppableId.slice(-1);
    if (parseInt(droppableNumber) === userAnswer) {
      setUserAnswer(undefined);
    } else {
      setUserAnswer(parseInt(droppableNumber));
    }
  };

  return (
    <>
      <DndContext onDragEnd={endDrag}>
        <StyledGrid columns={{ sm: 1, md: 1, lg: 2 }} justify="start">
          <ImageGridItem>
            <InteractiveWrapper data-id="interactive-wrapper">
              <ReactPlayerWrapper
                data-id="react-player-wrapper"
                ref={playerWrapperRef}
                $width={question.resolution.width}
                $height={question.resolution.height}
              >
                <StyledReactPlayer
                  data-id="react-player"
                  ref={playerRef}
                  url={videoUrl}
                  controls={false}
                  onError={handleError}
                  playing={playing}
                  muted={true}
                  playsinline={true}
                  onProgress={progress}
                  progressInterval={1}
                  width="100%"
                  height="100%"
                  pip={false}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        disablePictureInPicture: true,
                      },
                    },
                  }}
                />
              </ReactPlayerWrapper>
              {showDropzones && (
                <>
                  <ImageOnPause imageUrl={decodeHtmlEntities(question.imageUrl)} />
                  {question.potentialAnswers?.map((coordinates: Coordinates, index: number) => (
                    <DroppableSingle
                      key={"droppable" + index}
                      coordinates={{
                        x: coordinates.x * resizeRatio,
                        y: coordinates.y * resizeRatio,
                      }}
                      option={index}
                      userAnswer={userAnswer}
                      onClick={clickDroppable}
                    />
                  ))}
                </>
              )}
            </InteractiveWrapper>
          </ImageGridItem>
          <ScrollableGridItem feedbackIsShown={showCheckAnswer}>
            {!showCheckAnswer && (
              <DraggableContainer>
                <DraggableSingle key="draggable" isHidden={userAnswer !== undefined} />
              </DraggableContainer>
            )}

            {showCheckAnswer && !showFeedback && (
              <CheckOutAnswerAndRetryButton
                onClickRetryButton={resetAnswer}
                onClickShowAnswerButton={checkAnswer}
              />
            )}
            {showFeedback && (
              <Feedback
                feedback={question.feedback}
                isCorrectAnswer={question.potentialAnswers[userAnswer!].correct}
              />
            )}
          </ScrollableGridItem>
        </StyledGrid>
      </DndContext>
    </>
  );
};

export default Movie;
