import { IResult } from "@domain/types";
import trainersDefault from "@mocks/configurationsDefault.json";
import content from "@mocks/contentDefault.json";
import dynamicActivityDefault from "@mocks/dynamicActivityDefault.json";
import learningObjectDefault from "@mocks/learningObjectDefault.json";
import progress from "@mocks/progressDefault.json";
import resultsDefault from "@mocks/resultsDefault.json";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import config from "@public/config.js";
import { AUTH_AUTHORITY, AUTH_REDIRECT_URI } from "@utils/constants";
import { createServer } from "miragejs";

export default () =>
  createServer({
    routes() {
      this.namespace = "api";
      this.get(`${config.apiUrl}content`, () => content, { timing: 2000 });
      this.get(`${config.apiUrl}configurations`, (_schema, { queryParams }) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trainersDefault.filter(({ type }) => queryParams.type.includes(type)),
      );
      this.get(
        `${config.apiUrl}configurations/:id`,
        (_schema, { params: { id } }) =>
          trainersDefault.find((trainer) => trainer.id === id) || trainersDefault[0],
      );
      this.post(
        `${config.apiUrl}configurations/:id/dynamic-activities`,
        () => dynamicActivityDefault,
      );
      this.get(`${config.apiUrl}dynamic-activities/:id`, () => dynamicActivityDefault);
      this.get(
        `${config.apiUrl}dynamic-activities/:id/results`,
        () => resultsDefault as unknown as IResult,
      );
      this.get(`${config.apiUrl}user/:id/progress`, () => progress);
      this.get(`${config.apiUrl}users/:id/content`, () => content);
      this.get(`${config.apiUrl}learning-object/:id`, (_schema, request) => {
        const { id } = request.params;

        return JSON.parse(
          JSON.stringify(learningObjectDefault).replace(/{{CONTENT_ID}}/g, id),
        ) as typeof learningObjectDefault;
      });
      // this request can be used to mock a 403
      // to use this also add import {Response} from 'miragejs';
      //
      // this.get(`${config.apiUrl}configurations/:id`, () => new Response(403, { }, {
      //     message: "",
      //     error: "no_license",
      //     reason: "Missing license",
      //     type: "ACCESS_DENIED"
      //   }
      // ));

      // Allow unhandled requests on the current domain to pass through
      this.passthrough();
      this.passthrough(`${config.apiUrl}/**`); // important: specify domain if it's external
      this.passthrough(AUTH_REDIRECT_URI); // important: specify domain if it's external
      this.passthrough(`${AUTH_AUTHORITY}/**`); // important: specify domain if it's external
    },
  });
