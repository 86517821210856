import { ReactNode } from "react";

import { MarkdownSanitized } from "@components/MarkdownSanitized/MarkdownSanititzed";

import BodyLarge from "../../typography/BodyLarge";

import { FeedbackContent } from "./styles/feedbackText.styled";

type FeedbackTextProps = {
  feedback: { description: string | ReactNode };
};

const FeedbackText = ({ feedback }: FeedbackTextProps) => {
  return (
    <>
      {typeof feedback.description !== "string" ? (
        <FeedbackContent>{feedback.description}</FeedbackContent>
      ) : (
        <MarkdownSanitized markdown={feedback.description} container={BodyLarge} />
      )}
    </>
  );
};

export default FeedbackText;
