import { useEffect } from "react";
import "react-medium-image-zoom/dist/styles.css";

import ContentBlocksSwiper, {
  SwiperTypeVariant,
} from "content-types-shared/src/components/ContentBlocksSwiper/ContentBlocksSwiper";
import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import HeaderText from "content-types-shared/src/components/HeaderText/HeaderText";
import { ContentWrapper } from "content-types-shared/src/components/styles/contentWrapper.styled";
import "content-types-shared/src/styling/zoom.css";
import "swiper/css";

import Grid from "@anwb/poncho/components/grid";
import GridAside from "@anwb/poncho/components/grid/GridAside";
import GridItem from "@anwb/poncho/components/grid/GridItem";

import { CarouselContent } from "../types/types";

type ContentProps = {
  carousel: CarouselContent;
};

const Content = ({ carousel }: ContentProps) => {
  const dispatcher = useServerSideEventDispatcher();

  // This page is considered completed, when it's opened by the user.
  useEffect(() => {
    dispatcher.dispatch({ score: 0, maxScore: 0 });
  }, [dispatcher]);

  return (
    <ContentWrapper>
      <Grid columns={1} justify="start">
        <GridAside>
          <GridItem>
            <HeaderText title={carousel.title} hideOnMobile />
          </GridItem>
        </GridAside>
        <GridItem>
          <ContentBlocksSwiper
            title={carousel.title}
            variant={SwiperTypeVariant.BUTTONS}
            contentBlocks={carousel.contentBlocks}
          />
        </GridItem>
      </Grid>
    </ContentWrapper>
  );
};

export default Content;
