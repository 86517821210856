import ButtonDarkPrimary from "../ButtonDarkPrimary";
import ButtonDarkTertiary from "../ButtonDarkTertiary";
import { ButtonWrapper } from "../styles/checkAnswerAndResetButton.styled";

type ButtonProps = {
  onClickRetryButton: () => void;
  onClickShowAnswerButton: () => void;
};
const CheckOutAnswerAndRetryButton = ({
  onClickRetryButton,
  onClickShowAnswerButton,
}: ButtonProps) => {
  return (
    <ButtonWrapper>
      <ButtonDarkTertiary icon="refresh" onClick={onClickRetryButton}>
        Opnieuw
      </ButtonDarkTertiary>
      <ButtonDarkPrimary onClick={onClickShowAnswerButton}>Bekijk antwoord</ButtonDarkPrimary>
    </ButtonWrapper>
  );
};

export default CheckOutAnswerAndRetryButton;
