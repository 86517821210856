import ReactPlayer from "react-player/file";

import { ScaledImage } from "content-types-shared/src/components/Image/ScaledImage";
import styled, { css, keyframes } from "styled-components";

type ReactPlayerWrapperProps = {
  $width: number;
  $height: number;
};

const reactPlayerWrapperStyles = () => css`
  position: relative;
  height: 100%;
  max-height: 100%;
`;
export const ReactPlayerWrapper = styled.div<ReactPlayerWrapperProps>`
  ${reactPlayerWrapperStyles}
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  max-height: 100%;

  video {
    border-radius: 1.5rem;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const imageStyling = () => css`
  position: absolute;

  top: 0;
  margin: 0;
  left: 0;
  width: 100%;
  height: inherit;
  border-radius: 1.5rem;
  animation: ${fadeIn} 500ms linear;
`;

export const ImageOnPause = styled(ScaledImage)`
  ${imageStyling}
`;
