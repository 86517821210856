import Content from "./Content/Content";
import { YesNoQuestionProperties } from "./types/types";

type AppProps = {
  question: YesNoQuestionProperties;
};

const YesNoQuestion = ({ question }: AppProps) => {
  const lessonWithIds = {
    ...question,
    yesAnswer: { ...question.yesAnswer, id: `${Math.random().toString().substring(2)}` },
    noAnswer: {
      ...question.noAnswer,
      id: `${Math.random().toString().substring(2)}`,
    },
  };
  return lessonWithIds && <Content yesNoQuestion={lessonWithIds} />;
};

export default YesNoQuestion;
