import { useEffect, useState } from "react";

import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Feedback from "content-types-shared/src/components/Feedback/Feedback";
import {
  Arrow,
  CorrectAnswerIsTitle,
} from "content-types-shared/src/components/Feedback/styles/feedback.styled";
import HeaderText from "content-types-shared/src/components/HeaderText/HeaderText";
import SubHeaderText from "content-types-shared/src/components/SubHeaderText/SubHeaderText";
import { ContentWrapper } from "content-types-shared/src/components/styles/contentWrapper.styled";
import { ScrollableGridItem, StyledGrid } from "content-types-shared/src/styling/grid.styled";
import { decodeHtmlEntities } from "content-types-shared/src/utils/decodeHtmlEntities";

import GridItem from "@anwb/poncho/components/grid/GridItem";
import Notification from "@anwb/poncho/components/notification";

import { Answer, IHotSpot } from "../types/types";

import { FeedbackImageWrapper, ImageButton, ImageWrapper, ScaledImageHotspot } from "./styles";

type ContentProps = {
  hotspotQuestion: IHotSpot;
};

function renderFeedbackImages(answers: Answer[], selected?: number) {
  return [true, false].flatMap((isCorrect) =>
    answers
      .filter((_answer, index) => selected === undefined || index !== selected)
      .filter((answer) => answer.correctAnswer === isCorrect)
      .map((answer, index) => <FeedbackImage answer={answer} isSelected={false} key={index} />),
  );
}

const Content = ({ hotspotQuestion: { answers, question } }: ContentProps) => {
  const dispatcher = useServerSideEventDispatcher();
  const [selected, setSelected] = useState(-1);
  const { length } = answers.filter(({ correctAnswer }) => correctAnswer);
  if (length === 0 || length > 1) {
    return (
      <Notification
        variant="error"
        title={length === 0 ? "Geen correct antwoord." : "Dubbele correcte antwoorden."}
      />
    );
  }
  useEffect(() => {
    setSelected(-1);
  }, [answers]);

  const isUnselected = selected === -1;
  const isCorrectAnswerSelected = answers[selected]?.correctAnswer;
  const renderedFeedback = isCorrectAnswerSelected
    ? { description: renderFeedbackImages(answers) }
    : {
        description: (
          <>
            <FeedbackImage answer={answers[selected]} isSelected={true} />
            <CorrectAnswerIsTitle>
              Het juiste antwoord
              <Arrow />
            </CorrectAnswerIsTitle>
            {renderFeedbackImages(answers, selected)}
          </>
        ),
      };

  return (
    <>
      <ContentWrapper>
        <HeaderText title={question} />
        <SubHeaderText text="Klik op de juiste afbeelding" />
        <StyledGrid columns={{ sm: 1, md: 1, lg: 2 }} justify="start">
          <GridItem>
            <ImageWrapper>
              {answers.map((answer, index) => {
                return (
                  <ImageButton
                    active={isUnselected}
                    isSelected={index === selected}
                    isCorrect={answer.correctAnswer}
                    key={index}
                    onClick={() => {
                      if (isUnselected) {
                        const score = answer.correctAnswer ? 1 : 0;
                        dispatcher.dispatch({ score: score, maxScore: 1 });

                        setSelected(index);
                      }
                    }}
                    data-testid="hotspot-answer"
                  >
                    <ScaledImageHotspot imageUrl={answer.mediaURL} />
                  </ImageButton>
                );
              })}
            </ImageWrapper>
          </GridItem>
          {selected >= 0 && (
            <ScrollableGridItem feedbackIsShown={selected >= 0}>
              <div>
                <Feedback
                  feedback={renderedFeedback}
                  isCorrectAnswer={answers[selected]?.correctAnswer}
                />
              </div>
            </ScrollableGridItem>
          )}
        </StyledGrid>
      </ContentWrapper>
    </>
  );
};

export default Content;

const FeedbackImage = ({
  answer,
  isSelected = false,
}: {
  answer: Answer;
  isSelected?: boolean;
}) => {
  return (
    <FeedbackImageWrapper isCorrectAnswer={answer.correctAnswer} isSelected={isSelected}>
      <div>
        <ScaledImageHotspot imageUrl={answer.mediaURL} />
      </div>
      {decodeHtmlEntities(answer.feedback)}
    </FeedbackImageWrapper>
  );
};
