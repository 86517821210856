import styled from "styled-components";

import { Image } from "@anwb/poncho/components/image";

export const ImageStyled = styled(Image).attrs({
  decoding: "sync",
})`
  height: auto;
  border-radius: 1.5rem;
`;

export const InteractiveWrapper = styled.div`
  position: relative;
  height: 100%;
`;
