import { GlobalStyle } from "content-types-shared/src/styling/index.styled";
import { ANWBDarkBrandRefresh } from "content-types-shared/src/theme/dark-anwb-brand-refresh";
import { CustomFonts } from "content-types-shared/src/typography";
import { ThemeProvider } from "styled-components";

import { Fonts } from "@anwb/poncho/design-tokens/theme";

import Content from "./Content/Content";
import { InputQuestion } from "./types/types";

type AppProps = {
  inputQuestion: InputQuestion;
};

const Input = ({ inputQuestion }: AppProps) => {
  return (
    <ThemeProvider theme={ANWBDarkBrandRefresh}>
      <Fonts />
      <CustomFonts />
      <GlobalStyle />
      {inputQuestion && <Content inputQuestion={inputQuestion} />}
    </ThemeProvider>
  );
};

export default Input;
