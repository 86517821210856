import { createGlobalStyle, css } from "styled-components";

import { defaultFontStyle } from "../typography/styles/typography.styled";

export const getAbsoluteUrlToAsset = (path: string) => {
  const scriptPath = (document.currentScript as HTMLScriptElement).src;
  const relativeAssetPath = process.env.NODE_ENV === "development" ? "/../.." : "";
  return `${scriptPath.substring(0, scriptPath.lastIndexOf("/"))}${relativeAssetPath}${path}`;
};

const globalStyling = css`
  body {
    margin: 0;
  }
  .content-type {
    display: flex;
    flex-direction: column;
    height: 100%;

    ${defaultFontStyle};
  }

  p {
    margin: 0;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${globalStyling}
`;
