import HeaderText from "content-types-shared/src/components/HeaderText/HeaderText";
import SubHeaderText from "content-types-shared/src/components/SubHeaderText/SubHeaderText";
import { ContentWrapper } from "content-types-shared/src/components/styles/contentWrapper.styled";

import { StyledMovie } from "./DragAndDrop.styled";
import { DragAndDropQuestion } from "./types/types";

type AppProps = {
  options: DragAndDropQuestion;
};

const DragAndDrop = ({ options }: AppProps) => (
  <ContentWrapper>
    <HeaderText title={options.question} />
    <SubHeaderText text="Kies het juiste antwoord" />
    <StyledMovie question={options} />
  </ContentWrapper>
);

export default DragAndDrop;
