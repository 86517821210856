import styled, { css } from "styled-components";

import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "../../../theme/custom-variables";
import ButtonDarkTertiary from "../../ButtonDarkTertiary";

const selectedAnswerStyling = ({ theme }: DefaultThemeProps) => css`
  color: ${theme.colors.blanc.textButton};
  background-color: transparent;
  border-color: inherit;
  transition: 0.3s ease-in-out;
  cursor: default;
`;
const correctAnswerStyling = () => css`
  &&&& {
    ${selectedAnswerStyling};
    ${answerStyled};
    background-color: ${customVariables.colors.correctAnswerButtonTransparent};
    border-color: ${customVariables.colors.correctAnswerButton};
  }

  > span {
    color: ${customVariables.colors.correctAnswerButton};
  }
`;
const wrongAnswerStyling = () => css`
  &&&& {
    ${selectedAnswerStyling};
    ${answerStyled};
    background-color: ${customVariables.colors.wrongAnswerButtonTransparent};
    border-color: ${customVariables.colors.wrongAnswerButton};
  }

  > span {
    color: ${customVariables.colors.wrongAnswerButton};
  }
`;

const buttonIsShownInFeedback = () => css`
  &&&& {
    ${answerStyled};
    border-color: ${customVariables.colors.rightAnswerShownInFeedback};
    pointer-events: none;
    width: 100%;
    font-weight: 600;
  }
`;
const buttonIsSelected = ({ theme }: DefaultThemeProps) => css`
  &&&& {
    background-color: ${theme.colors.buttons.bgButtonPrimaryActive};
    color: ${customVariables.colors.textButtonInverted};
    height: auto;
    min-height: 3.2rem;
  }
`;

const answerIsMissing = () => css`
  &&&& {
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23d1413b' stroke-width='2' stroke-dasharray='25%2c 15' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    background-color: ${customVariables.colors.bodyBackground};
    border-radius: 30px;
    color: white;
    cursor: default;

    &:hover {
      background-color: ${customVariables.colors.bodyBackground};
    }
  }
`;

const answerStyled = () => css`
  &&&& {
    height: auto;
    padding-block: 0.5rem;
    min-height: 3.2rem;
    display: flex;
    justify-content: center;
    font-weight: 500;

    :hover > span {
      left: 0;
    }
  }
`;

export enum AnswerButtonStatus {
  ANSWER_DEFAULT,
  ANSWER_IS_CORRECT,
  ANSWER_IS_WRONG,
  ANSWER_IS_SELECTED,
  ANSWER_IS_SHOWN_IN_FEEDBACK,
  ANSWER_IS_MISSING,
}

const answerStyles = ({
  buttonStatus,
  theme,
}: DefaultThemeProps & { buttonStatus: AnswerButtonStatus }) => {
  switch (buttonStatus) {
    case AnswerButtonStatus.ANSWER_DEFAULT:
      return answerStyled();
    case AnswerButtonStatus.ANSWER_IS_CORRECT:
      return correctAnswerStyling();
    case AnswerButtonStatus.ANSWER_IS_WRONG:
      return wrongAnswerStyling();
    case AnswerButtonStatus.ANSWER_IS_SELECTED:
      return buttonIsSelected({ theme });
    case AnswerButtonStatus.ANSWER_IS_SHOWN_IN_FEEDBACK:
      return buttonIsShownInFeedback();
    case AnswerButtonStatus.ANSWER_IS_MISSING:
      return answerIsMissing();
    default:
      throw new Error("This buttonStatus does not exist");
  }
};

export const Answer = styled(ButtonDarkTertiary)`
  ${(props: DefaultThemeProps & { buttonStatus: AnswerButtonStatus }) => answerStyles(props)};
`;

export const AnswerWrapper = styled.div`
  ${Answer} {
    width: 100%;
  }
`;

export const HorizontalAnswerWrapper = styled(AnswerWrapper)`
  flex-direction: row;
  gap: 2rem;
`;

export const VerticalAnswerWrapper = styled(AnswerWrapper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const NotAllAnswersAreCorrect = styled(ButtonDarkTertiary)`
  ${(props: DefaultThemeProps & { buttonStatus: AnswerButtonStatus }) => answerStyles(props)};
`;
