import { useEffect, useState } from "react";

import { getButtonStatus } from "content-types-shared/src/components/AnswerButton/getButtonStatus";
import {
  Answer,
  VerticalAnswerWrapper,
} from "content-types-shared/src/components/AnswerButton/styles/answerButton.styled";
import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Feedback, { ContentTypes } from "content-types-shared/src/components/Feedback/Feedback";
import HeaderText from "content-types-shared/src/components/HeaderText/HeaderText";
import { ScaledImage } from "content-types-shared/src/components/Image/ScaledImage";
import Notification from "content-types-shared/src/components/Notification/Notification";
import SubHeaderText from "content-types-shared/src/components/SubHeaderText/SubHeaderText";
import { ContentWrapper } from "content-types-shared/src/components/styles/contentWrapper.styled";
import {
  ImageGridItem,
  ScrollableGridItem,
  StyledGrid,
} from "content-types-shared/src/styling/grid.styled";
import { PotentialAnswer } from "content-types-shared/src/types/types";
import { decodeHtmlEntities } from "content-types-shared/src/utils/decodeHtmlEntities";

import { YesNoQuestionProperties } from "../types/types";

type ContentProps = {
  yesNoQuestion: YesNoQuestionProperties;
};

const MIN_SCORE = 0;
const MAX_SCORE = 1;

const Content = ({ yesNoQuestion }: ContentProps) => {
  const dispatcher = useServerSideEventDispatcher();
  const [selectedAnswer, setSelectedAnswer] = useState<PotentialAnswer>();
  const answerOptions: PotentialAnswer[] = [yesNoQuestion.yesAnswer, yesNoQuestion.noAnswer];

  const correctAnswer = answerOptions.find((answer) => answer.correct);
  if (correctAnswer === undefined) {
    return (
      <Notification
        variant="warning"
        title="Deze vraag kan niet getoond worden vanwege een onvolledige configuratie"
      >
        Tip voor auteur: geef aan welk antwoord correct is.
      </Notification>
    );
  }

  const answerButtonClickHandler = (potentialAnswer: PotentialAnswer) => {
    setSelectedAnswer(potentialAnswer);

    const score = potentialAnswer.correct ? MAX_SCORE : MIN_SCORE;
    dispatcher.dispatch({ score: score, maxScore: MAX_SCORE });
  };

  useEffect(() => {
    setSelectedAnswer(undefined);
  }, [yesNoQuestion]);

  return (
    <>
      <ContentWrapper>
        <HeaderText title={yesNoQuestion.question} />
        <SubHeaderText text={"Selecteer het juiste antwoord."} />
        <StyledGrid columns={{ sm: 1, md: 1, lg: 2 }} justify="start">
          <ImageGridItem>
            <ScaledImage imageUrl={yesNoQuestion.imageUrl} />
          </ImageGridItem>
          <ScrollableGridItem feedbackIsShown={selectedAnswer !== undefined}>
            <VerticalAnswerWrapper>
              {(!selectedAnswer || selectedAnswer.id === yesNoQuestion.yesAnswer.id) && (
                <Answer
                  onClick={() => {
                    answerButtonClickHandler(yesNoQuestion.yesAnswer);
                  }}
                  icon={
                    selectedAnswer ? (selectedAnswer.correct ? "checkbox" : "cross") : undefined
                  }
                  buttonStatus={getButtonStatus(yesNoQuestion.yesAnswer, selectedAnswer)}
                  data-testid="yes-answer"
                >
                  {decodeHtmlEntities(yesNoQuestion.yesAnswer.answer)}
                </Answer>
              )}
              {(!selectedAnswer || selectedAnswer.id === yesNoQuestion.noAnswer.id) && (
                <Answer
                  onClick={() => {
                    answerButtonClickHandler(yesNoQuestion.noAnswer);
                  }}
                  icon={
                    selectedAnswer ? (selectedAnswer.correct ? "checkbox" : "cross") : undefined
                  }
                  buttonStatus={getButtonStatus(yesNoQuestion.noAnswer, selectedAnswer)}
                  data-testid="no-answer"
                >
                  {decodeHtmlEntities(yesNoQuestion.noAnswer.answer)}
                </Answer>
              )}
            </VerticalAnswerWrapper>
            {selectedAnswer && (
              <Feedback
                feedback={yesNoQuestion.feedback}
                isCorrectAnswer={selectedAnswer?.correct}
                correctAnswer={correctAnswer.answer}
                contentType={ContentTypes.YES_NO_QUESTION}
              />
            )}
          </ScrollableGridItem>
        </StyledGrid>
      </ContentWrapper>
    </>
  );
};

export default Content;
