import { useEffect, useRef, useState } from "react";

import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { Coordinates } from "@dnd-kit/utilities";
import CheckOutAnswerAndRetryButton from "content-types-shared/src/components/CheckOutAnswerAndRefreshButton/CheckOutAnswerAndRetryButton";
import { DraggableMultiple } from "content-types-shared/src/components/Draggable/DraggableMultiple";
import { DroppableMultiple } from "content-types-shared/src/components/Droppable/DroppableMultiple";
import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import Feedback from "content-types-shared/src/components/Feedback/Feedback";
import {
  ImageStyled,
  InteractiveWrapper,
} from "content-types-shared/src/components/styles/imageContainer.styled";
import useResizeObserver from "content-types-shared/src/hooks/useResizeObserver";
import {
  DraggableContainer,
  DraggableWrapper,
} from "content-types-shared/src/styling/draggable.styled";
import {
  ImageGridItem,
  ScrollableGridItem,
  StyledGrid,
} from "content-types-shared/src/styling/grid.styled";
import { scaleBynderImage } from "content-types-shared/src/utils/image";

import { useContentTypeStore } from "../store/useContentTypeStore";
import { MultipleSleepQuestion, PotentialAnswer } from "../types/types";

type ImageProps = {
  question: MultipleSleepQuestion;
};

function getNextAvailableNumber(selectedValues: number[]) {
  const sortedArr = [...selectedValues].sort((a, b) => a - b);

  for (let nextNumber = 0; nextNumber < sortedArr.length; nextNumber++) {
    if (sortedArr[nextNumber] !== nextNumber) {
      return nextNumber;
    }
  }

  return sortedArr.length;
}

const MIN_SCORE = 0;
const MAX_SCORE = 1;

export const ImageContainer = ({ question }: ImageProps) => {
  const dispatcher = useServerSideEventDispatcher();
  const imageRef = useRef(null);
  const { width } = useResizeObserver(imageRef);
  const resizeRatio =
    width !== undefined
      ? Math.round((width / question.resolution.width + Number.EPSILON) * 100) / 100
      : 1;

  const [selectedAnswer, setSelectedAnswer] = useState<PotentialAnswer>();

  const { userAnswers } = useContentTypeStore((state) => ({
    userAnswers: state.userAnswers,
  }));

  const { setUserAnswers, reset } = useContentTypeStore();

  useEffect(() => {
    reset();
    setSelectedAnswer(undefined);
  }, [question]);

  const showCheckAnswer =
    userAnswers &&
    Object.keys(userAnswers).length === question.coordinates.length &&
    !Object.values(userAnswers).includes(undefined);

  const endDrag = (onDragEndEvent: DragEndEvent) => {
    if (onDragEndEvent.over !== null) {
      /* onDragEndEvent.active.id is draggable0, draggable1 or draggable2 */
      const draggableNumber = (onDragEndEvent.active.id + "").slice(-1);
      const droppableId = onDragEndEvent.over.id + "";
      const droppableNumber = droppableId.slice(-1);

      if (droppableNumber?.length) {
        setUserAnswers({ ...userAnswers, [droppableNumber]: draggableNumber });
      }
    }
  };

  const clickDroppable = (event: any) => {
    const droppableId = event.target.id;
    const droppableNumber = droppableId.slice(-1);

    const selectedValues = Object.values(userAnswers).map(Number);
    const nextSequentialValue = getNextAvailableNumber(selectedValues);

    if (nextSequentialValue < question.coordinates.length) {
      setUserAnswers({ ...userAnswers, [droppableNumber]: nextSequentialValue.toString() });
    }
  };
  const checkAnswer = () => {
    const givenAnswer = question.potentialAnswers.find((potentialAnswer: PotentialAnswer) => {
      if (userAnswers && userAnswers[0] && userAnswers[1]) {
        return (
          potentialAnswer.answer[0] === parseInt(userAnswers[0], 10) &&
          potentialAnswer.answer[1] === parseInt(userAnswers[1], 10)
        );
      }
      return false;
    });

    if (!givenAnswer) {
      return;
    }

    setSelectedAnswer(givenAnswer);

    const score = givenAnswer.correct ? MAX_SCORE : MIN_SCORE;
    dispatcher.dispatch({ score: score, maxScore: MAX_SCORE });
  };

  const resetAnswer = () => {
    setUserAnswers({});
    setSelectedAnswer(undefined);
  };

  return (
    <>
      <DndContext onDragEnd={endDrag}>
        <StyledGrid columns={{ sm: 1, md: 1, lg: 2 }} justify="start">
          <ImageGridItem>
            <InteractiveWrapper data-id="interactive-wrapper">
              <ImageStyled
                ref={imageRef}
                src={scaleBynderImage(question.imageUrl)}
                width={question.resolution.width}
                height={question.resolution.height}
              />
              {question.coordinates?.map((coordinates: Coordinates, index: number) => (
                <DroppableMultiple
                  key={"droppable" + index}
                  coordinates={{
                    x: coordinates.x * resizeRatio,
                    y: coordinates.y * resizeRatio,
                  }}
                  option={index}
                  userAnswer={userAnswers[index]}
                  onClick={clickDroppable}
                />
              ))}
            </InteractiveWrapper>
          </ImageGridItem>
          <ScrollableGridItem feedbackIsShown={showCheckAnswer}>
            {!showCheckAnswer && (
              <DraggableWrapper>
                {(question.coordinates || []).map((_, index: number) => (
                  <DraggableContainer key={index}>
                    <DraggableMultiple
                      option={index}
                      isHidden={Object.values(userAnswers).includes(index.toString())}
                    />
                  </DraggableContainer>
                ))}
              </DraggableWrapper>
            )}

            {showCheckAnswer && !selectedAnswer && (
              <CheckOutAnswerAndRetryButton
                onClickRetryButton={resetAnswer}
                onClickShowAnswerButton={checkAnswer}
              />
            )}
            {selectedAnswer && (
              <Feedback feedback={question.feedback} isCorrectAnswer={selectedAnswer.correct} />
            )}
          </ScrollableGridItem>
        </StyledGrid>
      </DndContext>
    </>
  );
};
