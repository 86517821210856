import styled from "styled-components";

import Typography from "@anwb/poncho/components/typography";

export const StyledNumber = styled(Typography).attrs({
  variant: "number",
})`
  color: white;
  font-size: 1.5rem;
`;
