import { NotificationProps } from "@anwb/poncho/components/notification";

import { NotificationStyled } from "./styles/notification.styled";

const Notification = ({ variant, title, children }: NotificationProps) => {
  return (
    <NotificationStyled variant={variant} title={title}>
      {children}
    </NotificationStyled>
  );
};

export default Notification;
