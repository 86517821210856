import styled, { css } from "styled-components";

import { ButtonTertiary } from "@anwb/poncho/components/button";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

const buttonStyles = ({ theme }: DefaultThemeProps) => css`
  && {
    border-radius: 2rem;
    border-width: 1.5px;
    :hover {
      border-color: ${theme.colors.blanc.textButton};
      background-color: #ffffff19;
    }

    :after {
      display: none;
    }
  }
`;

export default styled(ButtonTertiary).attrs({
  variant: "on-dark",
})`
  ${buttonStyles}
`;
