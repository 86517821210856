import { useEffect, useState } from "react";

import Progress from "@anwb/poncho/components/progress";

import { CountdownValue, ProgressBarWrapper } from "./styles/countdownTimer.styled";

export type CountdownProps = {
  duration: number;
  onComplete: () => void;
};

export const CountdownTimer = ({ duration, onComplete }: CountdownProps) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [showCountdown, setShowCountdown] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft === 0) {
        onComplete();
        setShowCountdown(false);
      } else {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, onComplete]);

  const progress = (timeLeft / duration) * 100 || 0;

  return (
    <ProgressBarWrapper>
      {showCountdown && (
        <>
          <CountdownValue>{`${timeLeft}`}</CountdownValue>
          <Progress percentage={progress} showPercentage={false} />
        </>
      )}
    </ProgressBarWrapper>
  );
};
