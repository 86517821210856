import { FunctionComponent, createContext, useContext, useMemo } from "react";

import { useConfigContext } from "@components/ConfigLoader";
import { DataClient } from "@services/api";

interface Clients {
  dataClient: DataClient;
}

export const ClientContext = createContext<Clients>({
  dataClient: new Proxy(new DataClient(""), {
    get: () => {
      throw new Error("Use ClientProvider to create an DataAPI object");
    },
  }),
});
export const useClient = () => useContext(ClientContext);

export const ClientProvider: FunctionComponent = ({ children }) => {
  const { apiUrl } = useConfigContext();
  const client = useMemo(() => {
    return {
      dataClient: new DataClient(apiUrl),
    };
  }, [apiUrl]);
  return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>;
};
