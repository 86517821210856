import { useContext, useMemo } from "react";

import { ThemeContext } from "styled-components";

import { getApplicationSize } from "@anwb/poncho/utilities/browser";

import { BynderDerivative, scaleBynderVideo } from "../utils/video";

/**
 * Returns Bynder video variant appropriate for the screen size / network connection.
 *
 * Intentionally caches the result: it's not really responsive, but determines screen size only on first render
 * to make sure the video URL does not change when resizing browser (or turning tablet).
 *
 * If given URL is not a Bynder URL, it returns it as given. If it is a Bynder URL, a web720 variant is returned
 * by default, but is 'updated' to a web1080 URL for large screens and fastest connection type if that's known.
 *
 * @return for Bynder URLs, a web720 or web1080 variant, otherwise the given URL
 * @param url original video URL
 */
export default (url: string): string => {
  const theme = useContext(ThemeContext);

  return useMemo(() => {
    let variant = BynderDerivative.Web720;
    const size = getApplicationSize(theme);

    const { effectiveType = "unknown" } = navigator.connection || {};

    if (size === "large" && ["unknown", "4g"].includes(effectiveType)) {
      variant = BynderDerivative.Web1080;
    }

    return scaleBynderVideo(url, variant);
  }, [url, theme]);
};
