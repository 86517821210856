import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

const contentWrapperStyled = ({ theme }: DefaultThemeProps) => css`
  position: relative;
  max-height: calc(100vh - 15rem);
  max-height: calc(100dvh - 15rem);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    max-height: calc(100vh - 10rem);
    max-height: calc(100dvh - 10rem);
  }

  @media screen and (orientation: landscape) and (max-width: ${pxToRem(
      theme.viewportBreakpoint.md,
    )}) {
    gap: 0.5rem;
    max-height: calc(100vh - 10rem);
    max-height: calc(100dvh - 10rem);
  }
`;
export const ContentWrapper = styled.div`
  ${contentWrapperStyled}
`;
