export enum BynderDerivative {
  Mp4 = "mp4",
  Webm = "webm",
  Web720 = "Web_1280x720",
  Web1080 = "Web_1920x1080",
}

const extensions: Readonly<Record<BynderDerivative, string>> = {
  [BynderDerivative.Mp4]: "mp4",
  [BynderDerivative.Webm]: "webm",
  [BynderDerivative.Web720]: "mp4",
  [BynderDerivative.Web1080]: "mp4",
};

export function scaleBynderVideo(
  url: string,
  bynderDerivative: BynderDerivative = BynderDerivative.Web720,
): string {
  if (url === "") {
    throw new Error("Could not scale video, because url input field is empty.");
  }
  const regex = /^https:\/\/merk.anwb.nl\/m\/([0-9a-z]*)\//;
  const matches = url.match(regex);
  if (matches === null) {
    return url;
  }

  const identifier = matches[1];
  const extension = extensions[bynderDerivative];
  return `https://merk.anwb.nl/m/${identifier}/${bynderDerivative}-video.${extension}`;
}
