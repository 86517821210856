import { ScaledImage } from "content-types-shared/src/components/Image/ScaledImage";
import { customVariables } from "content-types-shared/src/theme/custom-variables";
import styled, { css } from "styled-components";

type ButtonProps = {
  isSelected?: boolean;
  isCorrect?: boolean;
  active: boolean;
};

const imageWrapperStyles = ({ fullWidth = true }: { fullWidth?: boolean }) => css`
  display: flex;
  gap: 3%;
  justify-content: space-around;
  width: ${fullWidth ? "auto" : "50%"};
  margin: auto;
  padding: 3%;
  img {
    // ToDo: remove when core-ui css is no longer conflicting
    width: 100%;
  }
`;

export const ImageWrapper = styled.span`
  ${imageWrapperStyles}
`;

const feedbackImageWrapperStyles = ({
  isCorrectAnswer,
  isSelected,
}: {
  isCorrectAnswer: boolean;
  isSelected: boolean;
}) => css`
  min-height: 6.5rem;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 1rem;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.1);

  > div {
    width: 8rem;
    flex-shrink: 0;

    img {
      width: auto;
      height: auto;
      max-width: 8rem;
      max-height: 100%;
    }
  }

  ${isCorrectAnswer &&
  css`
    border: 2px solid ${customVariables.colors.correctAnswerBorder};
  `}
  ${isSelected &&
  !isCorrectAnswer &&
  css`
    border: 2px solid ${customVariables.colors.wrongAnswerBorder};
  `}
`;

export const FeedbackImageWrapper = styled.div`
  ${feedbackImageWrapperStyles}
`;

const imageButton = ({ isCorrect = false, isSelected = false, active }: ButtonProps) => css`
  cursor: pointer;
  filter: opacity(${active || isSelected ? 1 : 0.3});
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  outline: transparent;
  overflow: hidden;
  ${!active &&
  css`
    cursor: unset;
    transition: outline-color 1s ease-in-out;
    outline: ${isSelected &&
    `2px solid ${
      isCorrect
        ? customVariables.colors.correctAnswerTitle
        : customVariables.colors.wrongAnswerTitle
    }`};
  `}

  img {
    // ToDo: remove when core-ui css is no longer conflicting
    margin: 0;
    width: 100%;
    max-width: 15rem;
  }
`;

export const ImageButton = styled.a`
  ${imageButton}
`;

export const ScaledImageHotspot = styled(ScaledImage)`
  border-radius: 0.5rem;
`;
