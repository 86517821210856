import React from "react";

import { useServerSideEventDispatcher } from "content-types-shared/src/components/Event/Dispatcher/ServerSideEventDispatcherContext";
import { GlobalStyle } from "content-types-shared/src/styling/index.styled";
import { CustomFonts } from "content-types-shared/src/typography";
import Carousel from "src/content-types/content-type-carousel/Carousel";
import { CarouselContent } from "src/content-types/content-type-carousel/types/types";
import DragAndDrop from "src/content-types/content-type-drag-and-drop/DragAndDrop";
import { DragAndDropQuestion } from "src/content-types/content-type-drag-and-drop/types/types";
import Hotspot from "src/content-types/content-type-hotspot/Hotspot";
import { IHotSpot } from "src/content-types/content-type-hotspot/types/types";
import Input from "src/content-types/content-type-input/Input";
import { InputQuestion } from "src/content-types/content-type-input/types/types";
import LessonTheory from "src/content-types/content-type-lesson-theory/LessonTheory";
import { Lesson } from "src/content-types/content-type-lesson-theory/types/types";
import McQuestion from "src/content-types/content-type-mc-question/McQuestion";
import { MultipleChoiceQuestion } from "src/content-types/content-type-mc-question/types/types";
import MultipleSelect from "src/content-types/content-type-multiple-select/MultipleSelect";
import { MultipleSelectQuestion } from "src/content-types/content-type-multiple-select/types/types";
import MultipleSleep from "src/content-types/content-type-multiple-sleep/MultipleSleep";
import { MultipleSleepQuestion } from "src/content-types/content-type-multiple-sleep/types/types";
import SingleSleep from "src/content-types/content-type-single-sleep/SingleSleep";
import { SingleSleepQuestion } from "src/content-types/content-type-single-sleep/types/types";
import Video from "src/content-types/content-type-video/Video";
import { VideoProperties } from "src/content-types/content-type-video/types/types";
import YesNoQuestion from "src/content-types/content-type-yes-no-question/YesNoQuestion";
import { YesNoQuestionProperties } from "src/content-types/content-type-yes-no-question/types/types";

export function AnwbPlayer({
  contentType,
  contentTypeOptions,
}: {
  contentType: string;
  contentTypeOptions: unknown;
}) {
  console.info("Using new AnwbPlayer", contentType, contentTypeOptions);

  const dispatcher = useServerSideEventDispatcher();
  dispatcher.trackTime();

  const renderContentType = () => {
    switch (contentType) {
      case "yes-no":
        return <YesNoQuestion question={contentTypeOptions as YesNoQuestionProperties} />;
      case "video":
        return <Video video={contentTypeOptions as VideoProperties} />;
      case "single-sleep":
        return <SingleSleep options={contentTypeOptions as SingleSleepQuestion} />;
      case "mc-question":
        return <McQuestion question={contentTypeOptions as MultipleChoiceQuestion} />;
      case "carousel":
        return <Carousel carousel={contentTypeOptions as CarouselContent} />;
      case "hotspot":
        return <Hotspot hotspotQuestion={contentTypeOptions as IHotSpot} />;
      case "theory":
        return <LessonTheory lesson={contentTypeOptions as Lesson} />;
      case "multiple-select":
        return <MultipleSelect question={contentTypeOptions as MultipleSelectQuestion} />;
      case "multiple-sleep":
        return <MultipleSleep options={contentTypeOptions as MultipleSleepQuestion} />;
      case "drag-and-drop":
        return <DragAndDrop options={contentTypeOptions as DragAndDropQuestion} />;
      case "input":
        return <Input inputQuestion={contentTypeOptions as InputQuestion} />;
      default:
        throw new Error(`Content type "${contentType}" is not supported`);
    }
  };

  return (
    <>
      <CustomFonts />
      <GlobalStyle />
      {renderContentType()}
    </>
  );
}
