import { create } from "zustand";

type ContentTypeStore = {
  userAnswer: number | undefined;

  setUserAnswer: (userAnswer: any) => void;
};

export const useContentTypeStore = create<ContentTypeStore>((set) => ({
  userAnswer: undefined,

  setUserAnswer: (userAnswer: any) => set({ userAnswer }),
}));
